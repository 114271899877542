import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import OpenDocumentIcon from '@material-ui/icons/CloudUpload';
import ProcessingIcon from '@material-ui/icons/Sync';
import AnalyzeIcon from '@material-ui/icons/Visibility';

const CustomStepConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 22,
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },
}))(StepConnector);

const stepLabelStyles = makeStyles(theme => ({
  alternativeLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem',
  },
}));

const useCustomStepIconStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    zIndex: 1,
    color: 'white',
    width: 50,
    height: 50,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: 'white',
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    color: 'white',
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
  label: {
    root: {
      color: 'white',
    },
    color: theme.palette.primary.light,
  },
  alternativeLabel: {
    root: {
      color: 'white',
    },
    color: theme.palette.primary.light,
  },
  labelContainer: {
    root: {
      color: 'white',
    },
    color: 'white',
  },
}));

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <OpenDocumentIcon />,
    2: <ProcessingIcon />,
    3: <AnalyzeIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const labels = [
  'Open PDF, ePub, or DjVu file from your disk',
  'Wait for processing to finish',
  'Double-click any diagram to open analysis',
];
export default ({props}) => {
  const classes = stepLabelStyles();
  return (
    <Stepper alternativeLabel connector={<CustomStepConnector />} style={{ backgroundColor: 'inherit', marginTop: '2rem' }}>
      {labels.map(label => (
        <Step key={label} active={false}>
          <StepLabel StepIconComponent={CustomStepIcon} classes={classes}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
