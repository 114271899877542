import React, { Component } from 'react';

import 'chessboard-element';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import MediaQuery from 'react-responsive';

import PlayerSwitch from './PlayerSwitch';

import settings from '../../settings';

import { reverseFenOrientation } from './utils';

import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  container: {
    width: '400px',
    overflow: 'hidden',
  },
  topBar: {
    background: 'white',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 999,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  actionsContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  orientationFlip: {
    marginLeft: '0.5rem',
    textTransform: 'none',
  },
  castlingToggle: {
    marginLeft: '0.5rem',
    textTransform: 'none',
  },
  applyButton: {
    marginRight: theme.spacing(1),
  },
  saveButton: {
    marginRight: theme.spacing(1),
  },
  cancelButton: {
  },
  optionsContainer: {
  },
});

const addOptionsToFen = (fen, currentPlayer, castling) => {
  let castingStr = '';
  if (castling.white.kingside) {
    castingStr += 'K';
  }
  if (castling.white.queenside) {
    castingStr += 'Q';
  }
  if (castling.black.kingside) {
    castingStr += 'k';
  }
  if (castling.black.queenside) {
    castingStr += 'q';
  }
  if (castingStr === '') {
    castingStr = '-';
  }
  return fen + ' ' + (currentPlayer === 'white' ? 'w' : 'b') + ' ' + castingStr + ' - 0 1';
};

class EditMode extends Component {
  constructor(props) {
    super(props);
    const { fen } = this.props;
    const currentPlayer = fen.split('_')[1] === 'w' ? 'white' : 'black';
    const castlingStr = fen.split('_')[2];
    this.state = {
      currentPlayer,
      castling: {
        white: {
          kingside: castlingStr.includes('K'),
          queenside: castlingStr.includes('Q'),
        },
        black: {
          kingside: castlingStr.includes('k'),
          queenside: castlingStr.includes('q'),
        },
      },
      showCastling: false,
    };
  }

  handlePlayerChange = (e) => {
    this.setState({
      currentPlayer: e.target.value === 'white' ? 'black' : 'white',
    });
  }

  handleOrientationChange = (e) => {
    const { board } = this.state;
    const reversed = reverseFenOrientation(board.fen());
    board.setPosition(reversed);
  }

  handleSave = () => {
    const { onSave } = this.props;
    const { board, currentPlayer, castling } = this.state;
    const fen = board.fen();
    const fenWithOptions = addOptionsToFen(fen, currentPlayer, castling);
    onSave(fenWithOptions.split(' ').join('_'));
  }

  handleApply = () => {
    const { onApply } = this.props;
    const { board, currentPlayer, castling } = this.state;
    const fen = board.fen();
    const fenWithOptions = addOptionsToFen(fen, currentPlayer, castling);
    onApply(fenWithOptions.split(' ').join('_'));
  }

  handleCastlingChange = (e) => {
    const [ color, type ] = e.target.getAttribute('name').split('_');
    let { castling } = this.state;
    castling[color][type] = e.target.checked;
    this.setState({
      castling,
    });
  }

  toggleCastling = () => {
    this.setState({
      showCastling: !this.state.showCastling,
    });
  }

  render() {
    const { classes } = this.props;
    const { fen, onCancel, onSave } = this.props;
    const { showCastling } = this.state;
    const { currentPlayer, castling } = this.state;

    return (
      <MediaQuery maxWidth={settings.values.maxMobileWidth}>
      {(isMobile) => (
      <div className={classes.container} style={{ width: isMobile ? `${window.innerWidth}px` : '400px'}}>
        <div className={classes.topBar}>
          <div className={classes.actionsContainer}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.applyButton}
              onClick={() => this.handleApply()}
            >Apply</Button>
            { onSave && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.saveButton}
                onClick={() => this.handleSave()}
              >Save & Apply</Button>
            )}
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className={classes.cancelButton}
              onClick={onCancel}>Cancel</Button>
          </div>
          <div className={classes.optionsContainer}>
            <FormControlLabel
               control={
                <PlayerSwitch
                  className={classes.playerSwitch}
                  checked={currentPlayer === 'white'}
                  size="medium"
                  onChange={this.handlePlayerChange}
                  value={currentPlayer}
                />
               }
               label={currentPlayer === 'white' ? "White's turn" : "Black's turn"}
               labelPlacement="end"
             />
            <FormControlLabel
               control={
                 <Button
                  className={classes.orientationFlip}
                  size="small"
                  variant="outlined"
                  onClick={this.handleOrientationChange}
                >A1⇄H8</Button>
                }
             />
            <FormControlLabel
               control={
                 <Button
                  className={classes.castlingToggle}
                  size="small"
                  variant="outlined"
                  onClick={this.toggleCastling}
                >Castling</Button>
                }
             />
             { showCastling && (
               <FormControl component="fieldset" className={classes.castlingFormControl}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={castling.white.kingside}
                        onChange={this.handleCastlingChange}
                        name="white_kingside"
                    />}
                    label="White O-O"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={castling.white.queenside}
                        onChange={this.handleCastlingChange}
                        name="white_queenside"
                    />}
                    label="O-O-O"
                    labelPlacement="start"
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={castling.black.kingside}
                        onChange={this.handleCastlingChange}
                        name="black_kingside"
                    />}
                    label="Black O-O"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={castling.black.queenside}
                        onChange={this.handleCastlingChange}
                        name="black_queenside"
                    />}
                    label="O-O-O"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
             )}
         </div>
        </div>
        { /* in chess-board we modify state directly without setState to avoid infinite loop of update-render */}
        <chess-board
          position={fen.split('_')[0]}
          orientation='white'
          draggable-pieces
          spare-pieces
          drop-off-board="trash"
          ref={(e) => this.state.board = e}>
        </chess-board>
      </div>
      )}
      </MediaQuery>
    );
  }
}

export default withStyles(styles)(EditMode);
