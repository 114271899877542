import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';

import Footer from './../../Site/Footer';

const styles = (theme) => ({
  terms: {
    paddingTop: '6rem',
    '& p, li': {
      color: grey[800],
      lineHeight: '1.5rem',
    }
  },
});

class TermsContent extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.terms}>
          <Container maxWidth="md">
            <h2>Terms of Service</h2>

            <h3>1. Terms</h3>
            <p>
            By accessing Software or Services available at https://chessvision.ai or on any its subdomain, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
            </p>

            <h3>2. Use Licence</h3>
            <p>
            Subject to your compliance with the Terms and applicable law, we grant you a term subscription, software as a service (‘SaaS’), nonexclusive, and nontransferable right to access the Software or Services from us, under the specified rules. You may access and use the Services and Software that we make available, and that you license from us. Your license(s) expire at the end of the term set forth in your order document. The license(s) are non-transferable. You agree that your decision to use or purchase Software or Services is not contingent on the delivery of any future functionality or features, or dependent on any oral or written public comments made by us regarding future functionality or features. Under the license you may not:
            </p>
            <Typography component={'ol'} variant={'body1'}>
                <li>1. attempt to decompile or reverse engineer any our Software;</li>
                <li>2. resell the Services or Software;</li>
                <li>3. enable or allow others to use the Services or Software using your account information;</li>
                <li>4. attempt to abuse the service; including any attempts to use the service in a non-intended way or performing a mass number of requests;</li>
            </Typography>

            <h3>3. Content Ownership</h3>
            <p>
            Our Software and Services allow you to upload, submit, and store Content. You (as a Business or an individual, as applicable) retain all rights and ownership of your Content, and your Content remains yours. We do not claim ownership in any of your Content, including any text, data, information, and files that you upload, share, or store in your account. We are not responsible for any Content you upload, share or create using our Software or Services.
            </p>

            <h3>4. Program Policies</h3>
            <p>
            We may review Content to determine whether it is illegal or violates our Terms of Service, and we may remove or refuse to display Content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review Content, so please don’t assume that we do.
            </p>

            <h3>5. Our Access to Your Content</h3>
            <p>
            Where permitted by law, we will only access, view, or listen to your Content in limited ways. For example, in order to perform the Services, we may need to access or view your Content to (A) respond to Feedback or support requests; (B) detect, prevent, or otherwise address fraud, security, legal, or technical issues; and (C) enforce the Terms (D) improve the quality of our Software or Service. Our automated systems may analyze your Content using techniques such as machine learning in order to improve our Services and Software and the user experience.
            </p>

            <h3>6. Subscription Terms</h3>
            <p>
            Your subscription begins as soon as your initial payment is processed. Your subscription will automatically renew according to the chosen billing period without notice until you cancel. You authorize us to store your payment method(s) and to automatically charge your payment method(s) according to the chosen billing period until you cancel. We will automatically charge you the rate for your plan, plus applicable taxes (such as VAT or GST if the rate does not include it), according to the chosen billing period until you cancel.
            </p>

            <h3>7. Subscription Cancellation Terms</h3>
            <p>
            You can cancel your subscription anytime via your Account page. Your service will continue until the end of the current billing period.
            </p>

            <h3>8. One-time Purchases</h3>
            <p>
            Your One-time Purchases are effective as soon as the payment is processed, and will continue until our Service operates, or until the Licence is terminated.
            </p>

            <h3>9. Disclaimer</h3>
            <p>
            The materials we provide are on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
            Further, Chessvision.ai does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
            </p>

            <h3>10. Limitations</h3>
            <p>
            In no event shall Chessvision.ai or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Chessvision.ai website, even if Chessvision.ai or a Chessvision.ai authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
            </p>

            <h3>11. Accuracy of materials</h3>
            <p>
            The materials appearing on Chessvision.ai website could include technical, typographical, or photographic errors. Chessvision.ai does not warrant that any of the materials on its website are accurate, complete or current. Chessvision.ai may make changes to the materials contained on its website at any time without notice. However, we do not make any commitment to update the materials.
            </p>

            <h3>12. Links</h3>
            <p>
            Chessvision.ai has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Chessvision.ai of the site. Use of any such linked website is at the user's own risk.
            </p>

            <h3>13. Modifications</h3>
            <p>
            Chessvision.ai may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.
            </p>

            <h3>14. Governing Law</h3>
            <p>
            These terms and conditions are governed by and construed in accordance with the laws of Poland and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            </p>

          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

TermsContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsContent);
