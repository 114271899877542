import React from 'react';
import './Search.css';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';

import 'chessboard-element';
import { objToFen, validFen, START_FEN } from './chess-utils';
import Select from 'react-select';

import { openings, phases, opennesses, endgameTypes, pawnStructures, themesOptions } from './data';

const styles = (theme) => ({
  container: {
    textAlign: 'left',
  },
  selectsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectWrapper: {
    width: '25%',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
    position: 'relative',
  },
  select: {
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  inputWrapper: {
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    marginTop: '1px',
  },
  themesWrapper: {
  },
  fenEditorWrapper: {
    zIndex: 999,
    top: '3rem',
    left: '0.4rem',
    backgroundColor: '#ccc',
    position: 'absolute',
    paddingBottom: '1rem',
    overflow: 'visible',
  },
  fenEditorButtons: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

class Filters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      textFen: null,
      editorFen: START_FEN,
      editorOpen: false,
    }
    this.board = null;
  }
  componentDidMount() {
    this.setupDragElement();
  }
  toggleEditor = (open) => {
    this.setState({ editorOpen: open }, () => {
      this.setupDragElement();
    });
  }
  setupDragElement = () => {
    const checkTimeout = 100;
    const checkElem = () => {
      const { editorOpen } = this.state;
      const shadowRoot = document.getElementById('search-editor-board')?.shadowRoot;
      if (shadowRoot) {
        const dragElem = shadowRoot.querySelector('#dragged-pieces');
        if (dragElem) {
          dragElem.style.setProperty('position', 'fixed');
          dragElem.style.setProperty('top', `-${window.scrollY}px`);
          dragElem.style.setProperty('left', 0);
          document.addEventListener('scroll', () => {
            dragElem.style.setProperty('top', `-${window.scrollY}px`);
          });
        } else if (editorOpen) {
          setTimeout(checkElem, checkTimeout);
        }
      } else if (editorOpen) {
        setTimeout(checkElem, checkTimeout);
      }
    }
    checkElem();
  }
  handleBoardChange = (e) => {
    const fen = objToFen(e.detail.value);
    const updates = {
      editorFen: fen,
    }
    if (fen !== this.state.textFen) {
      updates.textFen = fen;
    }
    this.setState(updates);
  }
  handleFenChange = (e) => {
    const fen = e.target.value;
    if (fen === '') {
      this.setState({ editorFen: START_FEN });
    } else if (validFen(fen)) {
      const updates = { editorFen: fen };
      if (fen !== this.state.textFen) {
        updates.textFen = fen;
      }
      this.setState(updates);
    } else {
      this.setState({ textFen: fen })
    }
  }
  handleEditorApply = (e) => {
    const { onChange } = this.props;
    this.toggleEditor(false);
    onChange('fen', this.state.editorFen);
  }
  handleEditorClear = (e) => {
    const emptyFen = '8/8/8/8/8/8/8/8';
    this.setState({
      textFen: emptyFen,
      editorFen: emptyFen,
    });
  }
  handleFenClear = (e) => {
    const { onChange } = this.props;
    this.toggleEditor(false);
    onChange('fen', null);
  }

  render() {
    const { classes } = this.props;
    const { onChange } = this.props;

    const { doc, fen, opening, variation, phase, openness, endgameType, pawnStructure, themes } = this.props;
    const { docs } = this.props;

    const { textFen } = this.state;
    const { editorOpen, editorFen } = this.state;

    const disabled = docs === null;
    return (
      <div className={classes.container}>
        <div className={classes.selectsWrapper}>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Document"
              options={docs === null ? [] : docs.map(o => ({label: o.title, value: o.id}))}
              value={doc}
              onChange={o => onChange('doc', o)}
              disabled={disabled}
            />
          </div>
          <div className={classes.selectWrapper}>
            <div className={classes.inputWrapper}>
              <TextField
                id="fen-input"
                placeholder="FEN"
                value={editorOpen ? (textFen ? textFen : editorFen) : (fen ? fen : '')}
                variant="outlined"
                size="small"
                fullWidth
                margin="dense"
                style={{
                  height: '1.063rem',
                  marginTop: '3px',
                }}
                inputProps={{
                  style: { height: '1.063rem'},
                }}
                InputProps={{
                  endAdornment: (editorOpen || fen !== null)
                  ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => { e.stopPropagation(); this.handleFenClear() }}
                        style={{ padding: 0, marginRight: '-0.5rem' }}
                      ><ClearIcon size="small" /></IconButton>
                    </InputAdornment>
                  ) : null
                }}
                onClick={(e) => { e.stopPropagation(); this.toggleEditor(true); }}
                onChange={(value) => this.handleFenChange(value)}
                disabled={disabled}
              />
            </div>
            { editorOpen && (
            <ClickAwayListener onClickAway={ (e) => { if (e.target.getAttribute('id') !== 'fen-input') { this.toggleEditor(false) }}}>
              <div id="fen-editor-wrapper" className={classes.fenEditorWrapper} style={{ visibility: editorOpen ? 'visible' : 'hidden'}}>
                <chess-board
                  id="search-editor-board"
                  position={editorFen.split('_')[0]}
                  orientation='white'
                  draggable-pieces
                  spare-pieces
                  hide-notation
                  drop-off-board="trash"
                  style={{ width: '226px'}}
                  ref={(e) => {
                    this.board = e;
                    if (this.board) {
                      this.board.removeEventListener('change', this.handleBoardChange);
                      this.board.addEventListener('change', this.handleBoardChange);
                    }
                   }}>
                </chess-board>
                <div className={classes.fenEditorButtons}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<CheckIcon />}
                    onClick={() => this.handleEditorApply()}>Apply FEN</Button>
                  <Button
                    size="small"
                    color="default"
                    onClick={() => this.handleEditorClear()}>Clear Board</Button>
                </div>
              </div>
            </ClickAwayListener>
            )}
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Opening name"
              options={Object.keys(openings).map(o => ({label: o, value: o}))}
              value={opening}
              onChange={o => onChange('opening', o)}
              disabled={disabled}
            />
            { opening && (
              <Select
                className={classes.select}
                isClearable={true}
                isSearchable={true}
                placeholder="Variation"
                options={openings[opening.value].map(o => ({label: o, value: o}))}
                value={variation}
                onChange={o => onChange('variation', o)}
                disabled={disabled}
              />
            )}
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Game phase"
              options={phases}
              value={phase}
              onChange={o => onChange('phase', o)}
              disabled={disabled}
            />
            { phase && phase.value === 'endgame' && (
              <Select
                className={classes.select}
                isClearable={true}
                isSearchable={true}
                placeholder="Endgame Type"
                options={endgameTypes}
                value={endgameType}
                onChange={o => onChange('endgameType', o)}
                disabled={disabled}
              />
            )}
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Position openness"
              options={opennesses}
              value={openness}
              onChange={o => onChange('openness', o)}
              disabled={disabled}
            />
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Pawn structure"
              options={pawnStructures}
              value={pawnStructure}
              onChange={o => onChange('pawnStructure', o)}
              disabled={disabled}
            />
          </div>
        </div>
        <div className={classes.themesWrapper}>
          <Select
            className={classes.select}
            isMulti
            isClearable={true}
            isSearchable={true}
            placeholder="Themes"
            options={themesOptions}
            value={themes}
            onChange={o => onChange('themes', o)}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Filters);
