import React from 'react';

import CheckoutButton from './CheckoutButton';
import CustomerPortalButton from './CustomerPortalButton';

export const PurchaseButton = ({ checksum, ...rest }) => {
  const url = `${process.env.REACT_APP_API_URL}/checkout_sessions/purchases`;
  const payload = {
    checksum,
  };
  return (
    <CheckoutButton url={url} payload={payload} {...rest} />
  );
}

export const SubscribeButton = ({ plan, ...rest }) => {
  const url = `${process.env.REACT_APP_API_URL}/checkout_sessions/subscriptions`;
  const payload = {
    plan,
  };
  return (
    <CheckoutButton url={url} payload={payload} {...rest} />
  );
}

export { CustomerPortalButton };
