import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Award = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2">Award-winning app</Typography>
      <Typography variant="h6">The Reader won the Best Chess Startup 2020 award at the ChessTech2020 Conference</Typography>
      <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Best Chess Startup 2020 is <a href="https://twitter.com/ChessvisionAI?ref_src=twsrc%5Etfw">@ChessvisionAI</a> by Pawel Kacprzak from Wroclaw, Poland. <a href="https://twitter.com/AcornChess?ref_src=twsrc%5Etfw">@AcornChess</a> by Andrew Webb &amp; Andrew Varney from the UK gets the Honorary Mention as a close runner-up. Both present again in our Sunday sessions.<br/>A big thank you to all competitors and jurors!😀👏</p>&mdash; ChessTech2020 (@LondonChessConf) <a href="https://twitter.com/LondonChessConf/status/1335315469025366016?ref_src=twsrc%5Etfw">December 5, 2020</a></blockquote>
    </div>
  );
};

export default Award
