import CryptoJS from 'crypto-js';

export const fileChecksum = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function (e) {
        const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(e.target.result));
        const hashStr = hash.toString(CryptoJS.enc.Hex);
        resolve(hashStr);
    }
    reader.readAsBinaryString(file);
  });
}

export const truncate = (str, maxLen) => {
  const suffix = '...';
  if (str.length + suffix.length > maxLen) {
    return str.substr(0, maxLen - suffix.length) + suffix;
  }
  return str;
}
