import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { formatRelative } from 'date-fns';

import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog/ConfirmationDialog';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import PinIcon from 'mdi-material-ui/Pin';
import PinOutlineIcon from 'mdi-material-ui/PinOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RenameIcon from '@material-ui/icons/BorderColor';

import MediaQuery from 'react-responsive';

import Footer from '../../Site/Footer';

import { PurchaseButton } from '../../buttons';

import settings from '../../settings';
import { truncate } from '../../utils';

const styles = (theme) => ({
  root: {
    background: 'white',
    margin: '0 auto',
    marginTop: theme.spacing(15),
    textAlign: 'center',
    paddingBottom: theme.spacing(8),
    maxWidth: 1024,
    minHeight: 400,
  },
  instructions: {
    marginTop: theme.spacing(3),
    lineHeight: '1.5rem',
  },
  loading: {
    marginTop: theme.spacing(3),
  },
  searchText: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  table: {
    // minWidth: 650,
    maxWidth: 1024,
    margin: '0 auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    overflowX: 'auto',
    '& th, & td': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    '& td': {
      [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
        fontSize: '0.6rem',
      },
    }
  },
  actionsCell: {
    textAlign: 'center',
    paddingRight: theme.spacing(1),
  },
});

const getAnalysisStatusText = (analysis) => {
  let text = settings.messages.reader.analyzingFile;
  if (analysis.status !== null) {
    const { status } = analysis;
    text = `Analyzing: step ${status.step}/${status.total_steps}: ${status.operation}`;
    if (status.operation === 'finding diagrams') {
      text += ` on pages ${status.from_page}-${status.to_page}`;
    }
  }
  return text;
}

// sorting implementation follows
// https://material-ui.com/components/tables/
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const normalizeTitle = s => {
  // makes it lowercase
  // replaces '_' and '_' with spaces
  // removes '.pdf' extension
  // finally, trims the string
  return s.toLowerCase().replace(/_|-/g, ' ').replace('.pdf', '').trim();
}

class MyDocumentsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisList: undefined,
      deleteAnalysisDialog: {
        open: false,
        analysisId: null,
        isPerformingOkAction: false,
      },
      renameAnalysisDialog: {
        open: false,
        analysisId: null,
        isPerformingOkAction: false,
        title: null,
      },
      order: 'desc',
      orderBy: 'created_at',
      searchQuery: '',

      menuElem: null,
      menuOpenForAnalysisId: null,

      updatingFavoriteAnalysisId: null,
    };
    this.fetchTimeout = null;
  }

  componentDidMount() {
    this.fetchAnalysisList();
  }

  componentWillUnmount() {
    if (this.fetchTimeout !== null) {
      clearTimeout(this.fetchTimeout);
    }
  }

  openMenu = (event, analysisId) => {
    this.setState({ 
      menuElem: event.currentTarget,
      menuOpenForAnalysisId: analysisId,
    });
  };

  closeMenu = () => {
    this.setState({ 
      menuElem: null,
      menuOpenForAnalysisId: null,
    });
  };

  openDeleteAnalysisDialog = (analysisId) => {
    this.closeMenu();
    this.setState({
      deleteAnalysisDialog: {
        open: true,
        analysisId,
        isPerformingOkAction: false,
      }
    });
  };

  closeDeleteAnalysisDialog = (callback) => {
    this.setState({
      deleteAnalysisDialog: {
        open: false,
        analysisId: null,
        isPerformingOkAction: false,
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openRenameAnalysisDialog = (analysisId, title) => {
    this.closeMenu();
    this.setState({
      renameAnalysisDialog: {
        open: true,
        analysisId,
        title,
        isPerformingOkAction: false,
      }
    });
  };

  handleRenameAnalysisTitleChange = (e) => {
    const { renameAnalysisDialog } = this.state;
    this.setState({
      renameAnalysisDialog: {
        ...renameAnalysisDialog, 
        title: e.target.value,
      }
    });
  }

  closeRenameAnalysisDialog = (callback) => {
    this.setState({
      renameAnalysisDialog: {
        open: false,
        analysisId: null,
        title: null,
        isPerformingOkAction: false,
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  fetchAnalysisList = () => {
    const { user } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/analysis/`;

    return user.getIdToken().then(authToken => {
      return fetch(url, {
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         })
       })
       .then((response) => response.json())
       .then((data) => {
         let anyRunning = false;
         data = data.map((item) => {
           const { id, checksum, title, created_at, finished_at, errors, status, full_access, price, favorite } = item;
           if (finished_at === null) {
             anyRunning = true;
           }
           return {
             id,
             checksum,
             title,
             created_at: created_at ? new Date(created_at) : null,
             finished_at: finished_at ? new Date(finished_at) : null,
             full_access,
             errors,
             status,
             price,
             favorite,
           };
         })
         this.setState({
           analysisList: data,
         }, () => {
           if (anyRunning) {
              this.fetchTimeout = setTimeout(this.fetchAnalysisList,
                settings.values.analysisPoolInterval);
           }
         });
       })
    });
  }

  rerunAnalysis = (analysisId) => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/${analysisId}`;
      return fetch(url, {
        method: 'PATCH',
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
      .then(() => {
      });
    })
  }

  deleteAnalysis = () => {
    const { user } = this.props;
    const { deleteAnalysisDialog } = this.state;
    const { analysisId } = deleteAnalysisDialog;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/${analysisId}`;
      this.setState({
        deleteAnalysisDialog: {
          ...deleteAnalysisDialog,
          isPerformingOkAction: true,
        }
      });
      return fetch(url, {
        method: 'DELETE',
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
      .then(() => {
        const { analysisList } = this.state;
        this.setState({
          analysisList: analysisList.filter(analysis => analysis.id !== analysisId),
        }, () => {
          this.closeDeleteAnalysisDialog();
        })
      });
    })
  }

  renameAnalysis = () => {
    const { user } = this.props;
    const { renameAnalysisDialog } = this.state;
    const { analysisId } = renameAnalysisDialog;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/metadata/${analysisId}`;
      this.setState({
        renameAnalysisDialog: {
          ...renameAnalysisDialog,
          isPerformingOkAction: true,
        }
      });
      const data = {
        title: renameAnalysisDialog.title,
      };
      return fetch(url, {
        method: 'PATCH',
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data)
      })
      .then(() => {
        const { analysisList } = this.state;
        this.setState({
          analysisList: analysisList.map(analysis => analysis.id !== analysisId ? analysis : ({ ...analysis, title: renameAnalysisDialog.title })),
        }, () => {
          this.closeRenameAnalysisDialog();
        })
      });
    })
  }

  toggleAnalysisAsFavorite = (analysisId, currentFavoriteValue) => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/analysis/favorites/${analysisId}`;
      this.setState({
        updatingFavoriteAnalysisId: analysisId,
      });
      return fetch(url, {
        method: currentFavoriteValue === true ? 'DELETE' : 'POST',
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
      .then(() => {
        const { analysisList } = this.state;
        this.setState({
          analysisList: analysisList.map(analysis => analysis.id !== analysisId ? analysis : ({...analysis, favorite: !currentFavoriteValue })),
          updatingFavoriteAnalysisId: false,
        })
      })
      .catch(err => {
        console.error(err);
      });
    });
  }

  toggleSort = (newOrderBy) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === newOrderBy && order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: newOrderBy,
    });
  }

  handleSearchQueryChange = e => {
    this.setState({
      searchQuery: e.target.value,
    });
  }
  render() {
    // Styling
    const { classes } = this.props;

    const { user } = this.props;
    const { analysisList, deleteAnalysisDialog } = this.state;
    const { renameAnalysisDialog } = this.state;
    const { order, orderBy } = this.state;
    const { searchQuery } = this.state;
    const { menuElem } = this.state;
    const { menuOpenForAnalysisId } = this.state;

    const normalizedSearchQuery = normalizeTitle(searchQuery);

    return (
      <MediaQuery maxWidth={settings.values.maxMobileWidth}>
        {(isMobile) => (
          <React.Fragment>
            <div className={classes.root}>
              <Typography variant="h2">My Documents</Typography>
              { !isMobile && (
                <Typography className={classes.instructions}>
                  Here's a list of documents you sent for analysis and their statuses.
                  You can open any analyzed document from there by clicking "Open" button near it.
                  If you want to analyze any document again, first delete analysis related to that document by clicking delete button associate with it,
                  then go to the reader, open the document again and send it to analysis.
                </Typography>
              )}
              { analysisList === undefined
                ? <CircularProgress className={classes.loading} />
                : (
                  <>
                    <ConfirmationDialog
                      open={deleteAnalysisDialog.open}
                      title="Delete analysis for this document?"
                      content="If you choose to delete the analyze, the results of the analysis will be erased and in case you would to study that document again you will have to send it for a new analysis."
                      okText="Delete"
                      disableOkButton={false}
                      highlightCancelButton
                      onClose={this.closeDeleteAnalysisDialog}
                      onCancelClick={this.closeDeleteAnalysisDialog}
                      onOkClick={() => this.deleteAnalysis()}
                      isPerformingOkAction={deleteAnalysisDialog.isPerformingOkAction}
                    />
                    <ConfirmationDialog
                      open={renameAnalysisDialog.open}
                      title="Rename document"
                      okText="Rename"
                      disableOkButton={false}
                      highlightOkButton
                      onClose={this.closeRenameAnalysisDialog}
                      onCancelClick={this.closeRenameAnalysisDialog}
                      onOkClick={() => this.renameAnalysis()}
                      isPerformingOkAction={renameAnalysisDialog.isPerformingOkAction}
                    >
                      <TextField
                        label="Title"
                        value={renameAnalysisDialog.title}
                        onChange={this.handleRenameAnalysisTitleChange}
                        style={{ width: 480 }}
                      />
                    </ConfirmationDialog>
                    <TextField
                      label="Search by title"
                      placeholder="Title"
                      variant="outlined"
                      onChange={this.handleSearchQueryChange}
                      className={classes.searchText}
                      value={searchQuery}
                      />
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'title'}
                              direction={orderBy === 'title' ? order : 'asc'}
                              onClick={() => this.toggleSort('title')}
                            >Title</TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              active={orderBy === 'created_at'}
                              direction={orderBy === 'created_at' ? order : 'asc'}
                              onClick={() => this.toggleSort('created_at')}
                            >Created</TableSortLabel>
                          </TableCell>
                          <TableCell>Status</TableCell>
                          { !isMobile && (
                            <>
                              <TableCell>Errors</TableCell>
                              {/* <TableCell>Full Access</TableCell> */}
                            </>
                          )}
                          <TableCell className={classes.actionsCell}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { stableSort(
                          stableSort(analysisList.filter(e => searchQuery === '' || normalizeTitle(e.title).includes(normalizedSearchQuery)), getComparator(order, orderBy)),
                          getComparator("desc", "favorite"))
                          .map(item => (
                          <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                              {isMobile ? (<Tooltip title={item.title}><Typography>{truncate(item.title, 12)}</Typography></Tooltip>) : item.title }
                            </TableCell>
                            <TableCell>
                              {formatRelative(item.created_at, new Date())}
                            </TableCell>
                            <TableCell>
                              {item.finished_at
                                ? (
                                  <span>Finished<br />{formatRelative(item.finished_at, new Date())}
                                  </span>
                                )
                                : (
                                  <React.Fragment>
                                    <span>{item.errors ? 'interrupted because of error' : getAnalysisStatusText(item)}</span>
                                    <LinearProgress />
                                  </React.Fragment>
                                )
                              }
                            </TableCell>
                            { !isMobile && (
                              <>
                                <TableCell align="center">
                                  {item.errors ? item.errors : '-'}
                                </TableCell>
                                {/*
                                <TableCell align="center">
                                  {item.finished_at && item.full_access && (
                                    <CheckIcon />
                                  )}
                                  {settings.values.oneTimePurchaseEnabled && item.finished_at && !item.full_access && (
                                    <PurchaseButton user={user} checksum={item.checksum} text={`Get full access ($${item.price/100})`} fullWidth={false} />
                                  )}
                                  {!item.finished_at && (
                                    <span>-</span>
                                  )}
                                </TableCell>
                                */}
                              </>
                            )}
                            <TableCell className={classes.actionsCell}>
                              <React.Fragment>
                                { item.finished_at && (
                                  <Link to={{
                                    pathname: `${settings.routes.DOCUMENTS}/${item.checksum}`,
                                    state: {
                                      remoteFile: {
                                        id: item.id,
                                        checksum: item.checksum,
                                        title: item.title,
                                      },
                                    }
                                  }}
                                  style={{ textDecoration: 'none' }}
                                  >
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                    >
                                      Open
                                    </Button>
                                  </Link>
                                )}
                                <Tooltip title={item.favorite ? "Unpin from the top" : "Pin to the top"}>
                                  <IconButton
                                    variant="outlined"
                                    color={item.favorite ? "secondary" : "primary"}
                                    onClick={() => this.toggleAnalysisAsFavorite(item.id, item.favorite)}
                                  >{item.favorite ? <PinIcon/> : <PinOutlineIcon/>}
                                  </IconButton>
                                </Tooltip>
                                <IconButton
                                  variant="outlined"
                                  color="primary"
                                  onClick={(e) => this.openMenu(e, item.id)}
                                ><KeyboardArrowDownIcon/></IconButton>
                                <Menu
                                  anchorEl={menuElem}
                                  getContentAnchorEl={null}
                                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                                  open={menuOpenForAnalysisId === item.id}
                                  onClose={this.closeMenu}
                                >
                                  <MenuItem onClick={() => this.openRenameAnalysisDialog(item.id, item.title)}>
                                    <ListItemIcon>
                                      <RenameIcon/>
                                    </ListItemIcon>
                                    <Typography>Rename</Typography>
                                  </MenuItem>
                                  <MenuItem onClick={() => this.openDeleteAnalysisDialog(item.id)} style={{color: 'red'}}>
                                    <ListItemIcon>
                                      <DeleteIcon style={{color: 'red'}}/>
                                    </ListItemIcon>
                                    Delete
                                  </MenuItem>
                                </Menu>
                                { settings.values.showRerunButtons && (
                                  <Button variant="outlined" size="small" onClick={() => this.rerunAnalysis(item.id)}>
                                    Re-run
                                  </Button>
                                )}
                                { settings.values.showReportAnalysisIssue && (
                                  <Button variant="outlined" size="small"
                                    href={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader issue with analysis id=${item.id}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{ marginLeft: '1rem' }}
                                  >
                                    Report issue
                                  </Button>
                                )}
                              </React.Fragment>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </div>
              <Footer />
            </React.Fragment>
          )}
        </MediaQuery>
    );
  }
}

MyDocumentsContent.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyDocumentsContent);
