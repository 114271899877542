import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import QuoteIcon from '@material-ui/icons/FormatQuote';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import ZenchessAvatar from '../../assets/reviews/avatars/zenchess.jpeg';
import SamCopelandAvatar from '../../assets/reviews/avatars/sam_copeland.png';
import SwayamsMishraAvatar from '../../assets/reviews/avatars/swayams_mishra.jpg';


const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    marginTop: '1em',
  },
  cardHeaderTitle: {
    marginTop: 0,
  },
  review: {
    marginTop: theme.spacing(2),
    maxWidth: '440px',
    '@media (max-width:959px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  reviewText: {
    fontStyle: 'italic',
  },
  fideTitle: {
    background: '#b23330',
    color: '#fff',
    borderRadius: '25%',
    padding: '1px 4px',
    fontWeight: 600,
    marginRight: '4px',
    fontSize: '0.9rem',
  },
  nameLink: {
    color: '#6b7280',
  }
}));

const reviews = [
  {
    show: true,
    title: 'GM',
    name: 'Swayams Mishra',
    nickname: null,
    avatar: <Avatar alt='' src={SwayamsMishraAvatar} />,
    text: `Chessvision does everything as mentioned, and it is the 1st product that exceeds expectations.
    The ebook reader scans the positions & helps to study the books in a much better way and that's not all - it also allows you to add comments, search positions from online bases, youtube videos & analyze with the engine too.
    Definitely recommended for players of all levels interested to study & improve their chess.`,
    link: 'https://lichess.org/coach/mr_bh6',
  },
  {
    show: true,
    title: 'NM',
    name: 'Sam Copeland',
    nickname: null,
    avatar: <Avatar alt='Sam Copeland' src={SamCopelandAvatar} />,
    text: 'A fantastic app that unlocks the power of chess books!',
    link: 'https://youtube.com/SamSCopeland',
  },
  {
    show: true,
    title: null,
    name: 'Michael Bock',
    nickname: null,
    avatar: <Avatar alt='Michael Bock'>MB</Avatar>,
    text: `Very useful app in my arsenal of chess learning toolbox.
    With it, I am able to read through any chess book in pdf format, click on a diagram and have it instantly appear in an analysis window.
    It's a game changer in the chess tools world that I think anyone from a rank beginner to a grandmaster can benefit from!`,
  },
  {
    show: false,
    title: null,
    name: 'Jacob Wagner',
    nickname: 'Zenchess',
    avatar: <Avatar alt='Jacob Wagner' src={ZenchessAvatar} />,
    text: 'Chessvision.ai is a game-changer for reading chess books. You get an interactive chessboard by double-clicking on a diagram. Sure beats setting up positions piece by piece on a board or chess GUI.',
  },
];

const Reviews = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justify="space-evenly" className={classes.container}>
          {reviews.filter(e => e.show).map(review => {
            const verboseName = review.name + (review.nickname ? ` (${review.nickname})` : '');
            return (
              <Grid item key={review.name} xs={12} md={3}>
                <Card className={classes.review}>
                  <CardContent>
                    <Typography color="textSecondary" className={classes.reviewText}>
                      <QuoteIcon />
                      {review.text}
                    </Typography>
                  </CardContent>
                  <CardHeader
                    title={
                      <>
                      <Typography>
                        {review.title && (
                          <b className={classes.fideTitle}>{review.title}</b>
                        )}
                        {review.link
                          ? <a
                            href={review.link}
                            target="_blank"
                            rel="noreferrer noopener"
                            className={classes.nameLink}>{verboseName}</a>
                          : <span>{verboseName}</span>
                        }
                      </Typography>
                      { /* {review.links} */}
                      </>
                    }
                    avatar={review.avatar}
                    titleTypographyProps={{ align: 'center' }}
                    classes={{ root: classes.cardHeader, title: classes.cardHeaderTitle }}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

export default Reviews;
