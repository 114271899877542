import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import Container from '@material-ui/core/Container';

import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';

import settings from '../../settings';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  container: {
    paddingTop: theme.spacing(2),
  },
  card: {
    maxWidth: '440px',
    '@media (max-width:959px)': {
      margin: '0 auto',
      marginTop: theme.spacing(2),
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1),
  },
  price: {
    fontSize: '1.8rem',
  },
  priceCents: {
    fontSize: '1.25rem',
  },
  priceSecondaryInfo: {
    color: grey[600],
    fontSize: '1rem',
  },
  priceSeparator: {
    color: 'black',
    marginLeft: '0.5rem',
    marginRight: '0.6rem',
    fontSize: '1rem',
  },
  feature: {
    display: 'flex',
    color: grey[700],
    padding: '0.25rem 0',
  },
}));

const tiers = [
  {
    id: 'free',
    premium: false,
    title: 'Free',
    subheader: "See if it is for you",
    prices: [
      {
        dollars: 0,
      },
    ],
    description: [
      "Find out if it's for you",
      'Several pages analyzed in any eBook',
      'No credit card needed',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    id: 'member',
    premium: true,
    title: 'Member',
    subheader: 'Full functionality for unlimited eBooks',
    prices: [
      {
        dollars: settings.pricing.monthly.price.dollars,
        cents: settings.pricing.monthly.price.cents,
        unit: 'mo',
      },
      {
        dollars: settings.pricing.yearly.price.dollars,
        cents: settings.pricing.yearly.price.cents,
        unit: 'year',
      },
    ],
    description: [
      <span>Full functionality for <b>unlimited eBooks</b></span>,
      'Lifetime free updates',
      'Priority support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    id: 'one-time',
    premium: true,
    showOnlyForOneTimePurchaseEnabled: true,
    title: 'Per book',
    subheader: 'Full functionality for chosen eBook',
    prices: [
      {
        prefix: 'from',
        dollars: 9,
        cents: 99,
        unit: 'book',
      },
    ],
    description: [
      'Full functionality for chosen eBook',
      'One-time payment',
      'Priority support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
];

const Pricing = () => {
  const classes = useStyles();

  const tiersToDisplay = tiers.filter(tier => !tier.showOnlyForOneTimePurchaseEnabled || settings.values.oneTimePurchaseEnabled);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container justify="space-evenly" className={classes.container}>
            {tiersToDisplay.map(tier => (
              <Grid item key={tier.id} xs={12} md={tiersToDisplay.length === 3 ? 3 : 4}>
                <Card className={classes.card}>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center', color: tier.premium ? 'secondary' : 'inherit', variant: 'h4' }}
                    subheaderTypographyProps={{ align: 'center', variant: 'body1' }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      { tier.prices.map((price, i) => {
                        return (
                          <React.Fragment key={price.dollars}>
                            { price.prefix && (
                              <Typography variant="body1" className={classes.priceSecondaryInfo}>
                                {price.prefix}&nbsp;
                              </Typography>
                            )}
                            <Typography variant="h2" className={classes.price}>
                              ${price.dollars}
                            </Typography>
                            { price.cents && (
                              <Typography variant="h2" className={classes.priceCents}>
                                <sup>{price.cents}</sup>
                              </Typography>
                            )}
                            { price.unit && (
                              <Typography variant="body1" className={classes.priceSecondaryInfo}>
                                /{price.unit}
                              </Typography>
                            )}
                            { i+1 < tier.prices.length && (
                              <Typography variant="body1" className={classes.priceSeparator}>
                                or
                              </Typography>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </div>
                    <ul>
                      {tier.description.map(line => (
                        <Typography component="li" variant="body1" align="left" key={line} className={classes.feature}>
                          <ListItemIcon style={{ marginTop: '-2px', minWidth: '2.5rem' }}>
                            <CheckIcon style={{color: tier.premium ? 'green' : 'grey'}} fontSize="default" />
                          </ListItemIcon>
                          <span>{line}</span>
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Pricing;
