import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';

import grey from '@material-ui/core/colors/grey';

import { SubscribeButton } from '../../buttons';
import settings from '../../settings';

const styles = (theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardHeaderTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cardHeaderSubtitle: {
    fontSize: '2rem',
    fontWeight: 300,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  price: {
    fontSize: '1.8rem',
    fontWeight: 800,
  },
  priceUnit: {
    color: grey[600],
    fontSize: '1rem',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  feature: {
    display: 'flex',
    color: grey[700],
    padding: '0.25rem 0',
  },
});

const tiers = [
  {
    title: 'Monthly',
    subtitle: '',
    plan: settings.pricing.monthly,
    unit: 'month',
    description: [
      <span>Full functionality for <b>unlimited eBooks</b></span>,
      <span>All diagrams analyzed in <b>all</b> eBooks</span>,
      <span>Supports <b>PDF</b>, <b>ePub</b>, and <b>DjVu</b> formats</span>,
      'Lifetime free updates',
      'Priority support',
    ],
    buttonText: 'Select',
    buttonColor: 'primary',
    buttonVariant: 'outlined',
  },
  {
    title: 'Yearly',
    subtitle: 'Most Popular',
    plan: settings.pricing.yearly,
    unit: 'year',
    description: [
      <span>Full functionality for <b>unlimited eBooks</b></span>,
      <span>All diagrams analyzed in <b>all</b> eBooks</span>,
      <span>Supports <b>PDF</b>, <b>ePub</b>, and <b>DjVu</b> formats</span>,
      'Lifetime free updates',
      'Priority support',
    ],
    buttonText: 'Select',
    buttonColor: 'secondary',
    buttonVariant: 'contained',
  },
];

class SubscriptionPlansDialog extends Component {
  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.props.onOkClick();
    }
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { open } = this.props;

    // Custom Properties
    const {
      user,
      title,
      cancelText,
    } = this.props;

    // Dialog Events
    const { onClose } = this.props;

    // Custom Events
    const { onCancelClick, onOkClick } = this.props;

    if (!onClose) {
      return null;
    }

    return (
      <Dialog open={open} onClose={onClose} onKeyPress={this.handleKeyPress} maxWidth='md' fullWidth>
        {title && <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>}

        <DialogContent>
          <Container className={classes.container} maxWidth='md'>
            {tiers.map(tier => {
              const { plan, price } = tier.plan;
              const formattedPrice = `$${price.dollars}.${price.cents}`;
              // Enterprise card is full width at sm breakpoint
              return (
                <Grid item key={tier.title} xs={12} sm={5}>
                  <Card>
                    <CardHeader
                      title={tier.subtitle}
                      subheader={tier.title}
                      titleTypographyProps={{ align: 'center', color: 'secondary', variant: 'h4' }}
                      subheaderTypographyProps={{ align: 'center', variant: 'body1' }}
                      classes={{ root: classes.cardHeader, title: classes.cardHeaderTitle, subheader: classes.cardHeaderSubtitle }}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography variant="h2" className={classes.price}>
                          {formattedPrice}
                        </Typography>
                        { tier.unit && (
                          <Typography variant="body1" className={classes.priceUnit}>
                            /{tier.unit}
                          </Typography>
                        )}
                      </div>
                      <ul>
                        {tier.description.map((line, i) => (
                          <Typography component="li" variant="body1" align="left" key={i} className={classes.feature}>
                            <ListItemIcon style={{ marginTop: '-2px', minWidth: '2.5rem' }}>
                              <CheckIcon style={{color: 'green' }} fontSize="default" />
                            </ListItemIcon>
                            <span>{line}</span>
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions>
                      <SubscribeButton user={user} plan={plan} color={tier.buttonColor} disabled={false} text={tier.buttonText} fullWidth />
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Container>
        </DialogContent>

        {(onCancelClick || onOkClick) &&
          <DialogActions>
            {onCancelClick &&
              <Button color="primary" onClick={onCancelClick}>
                {cancelText || 'Cancel'}
              </Button>
            }
          </DialogActions>
        }
      </Dialog>
    );
  }
}

SubscriptionPlansDialog.defaultProps = {
  isPerformingOkAction: false,
};

SubscriptionPlansDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  user: PropTypes.object.isRequired,
  title: PropTypes.string,
  cancelText: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
};

export default withStyles(styles)(SubscriptionPlansDialog);
