import React from 'react';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player'


const videos = [
  {
    title: 'How it works?',
    chip: 'How it works?',
    description: 'Open a PDF, ePub, or DjVu chess book from your disk, wait for the processing to finish, and double-click on any diagram to open analysis. Each eBook is processed only once. Next time you open it, it is ready to use. The app uses Computer Vision and Artificial Intelligence to find and recognize diagrams in the books.',
    url: `https://www.youtube.com/watch?v=5wIv3OP7VWM`,
  },
  {
    title: 'My Documents',
    chip: 'My Documents',
    description: 'You can also open every already analyzed eBook directly from the App, as in cloud storage. Just go to the My Documents page and click "Open" button near the book you want to open. It gives you access to your eBooks across all your devices.',
    url: 'https://www.youtube.com/watch?v=HBnbiHunFNU',
  },
  {
    title: 'Study Creator',
    chip: 'Study Creator',
    description: 'You can use Study Creator to export your diagrams, e.g. to Lichess Studies. First, enable the Study Creator by clicking the switch near it. Next, after you loaded a diagram to the board and set whose turn is it, click on the "Add" button. When you added all diagrams you want, click on "Export".',
    url: 'https://www.youtube.com/watch?v=um9jiOl2hrI',
  },
  {
    title: 'Finding YouTube videos matching diagrams',
    chip: 'Finding YouTube videos',
    description: 'The Reader now automatically searches for YouTube videos matching the diagrams in your books!',
    url: 'https://www.youtube.com/watch?v=CmDNvVk7lHc',
  },
  {
    title: 'Searching diagrams in your books',
    chip: 'Diagram Search',
    description: 'You can use the Reader to search diagrams in your books! Use various filters and themes to quickly find positions you are looking for.',
    url: 'https://www.youtube.com/watch?v=Hq8-ocP-nAA',
  },
  {
    title: 'Diagram Editor',
    chip: 'Diagram Editor',
    description: ' In some rare cases, for example, if the book is of very low quality, it might happen that one of the pieces in incorrectly recognized. In such cases, you can edit the diagram manually and save the correction.',
    url: 'https://www.youtube.com/watch?v=GocLJBzCkO0',
  },
  {
    title: 'How to create PDFs with selected diagrams',
    chip: 'Creating PDFs',
    description: "You can select diagrams from your books and export them to a new PDF, with space for solutions and additional info. This is especially useful if you're teaching chess so that you can easily prepare exercises for your students.",
    url: 'https://www.youtube.com/watch?v=_vF1Enp0yY4',
  },
  {
    title: 'How to add Comments to Diagrams?',
    chip: 'Comments',
    description: 'You can add comments to diagrams by clicking the comment icon just above the chessboard. Comments are automatically saved. The orange indicator on the icon means that a diagram has a comment. Comments are also exported while using the Study Creator.',
    url: 'https://www.youtube.com/watch?v=5ZoiYsuB83c',
  },
  {
    title: 'Resizing the Analysis Board',
    chip: 'Resizing the Analysis Board',
    description: 'You can resize the Analysis Board by dragging its edge with a mouse. First, hover with a mouse over the edge and wait for the resize cursor to show up. Then drag it with a mouse to adjust the size however you like.',
    url: 'https://www.youtube.com/watch?v=edAyCyMc1KU',
  },
];

const styles = theme => ({
  container: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  videoWrapper: {
    margin: '0 auto',
    width: '500px',
  },
  chipsContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  chip: {
    margin: '0 0.5rem 1rem 0.5rem',
  },
});

class VideoTutorialsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: videos[0],
    };
  }

  handleChipClick = (video) => {
    this.setState({
      video,
    });
  }

  // How to render a Dialog allowing background interaction:
  // https://stackoverflow.com/a/61340061/948918
  render() {
    const { classes } = this.props;

    const { open, onClose } = this.props;
    const { video } = this.state;

    return (
      <div className={classes.container}>
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
        >
          <DialogTitle>
            {video.title}
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {video.description}
            </DialogContentText>
            <div className={classes.videoWrapper}>
              <ReactPlayer
                url={video.url}
                controls
                width={500}
                height={315}
              />
            </div>
            <div className={classes.chipsContainer}>
              {videos.map(v => (
                <Chip
                  key={v.chip}
                  label={v.chip}
                  onClick={() => this.handleChipClick(v)}
                  color={v.url === video.url ? "secondary" : "primary"}
                  className={classes.chip}
                />
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(VideoTutorialsDialog);
