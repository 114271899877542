import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  list: {
    '&>li': {
      listStyleType: 'decimal',
    }
  },
});

const instructions = [
  {
    name: 'Chrome',
    steps: [
      <>Open <b>Chrome preferences</b></>,
      <>Select <b>Privacy and security</b></>,
      <>Click <b>Cookies and other site data</b></>,
      <>Find section <b>Sites that can always use cookies</b></>,
      <>Add <b>lichess.org</b> there</>,
      <>Refresh the page with the Reader</>,
    ],
  },
  {
    name: 'Firefox',
    steps: [
      <>Open <b>Firefox preferences</b></>,
      <>Select <b>Privacy & Security</b></>,
      <>Find section <b>Cookies and Site Data</b></>,
      <>Click <b>Manage exception...</b></>,
      <>Add <b>lichess.org</b> as an <b>allowed exception</b></>,
      <>Refresh the page with the Reader</>,
    ],
  }
]

const ThirdPartyCookiesDialog = ({open, onClose, onCloseAndDoNotShow}) => {
  const classes = useStyles();
  return (
    <ConfirmationDialog
      open={open}
      title="Are you seeing no pieces on the analysis board?"
      content={
        <>
          <p>
            The Reader uses the Lichess analysis board, and embedding it requires the browser to have 3rd party cookies enabled for Lichess.
            If you don't have them enabled, you are now seeing an empty analysis board with no pieces on it.
            In order to fix it and use the Reader, follow the below instructions:
          </p>
          { instructions.map(({name, steps}) => (
            <ExpansionPanel key={name}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>{name}</ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ol className={classes.list}>
                 { steps.map((step, i) => (
                   <li key={`${name}-${i}`}>{step}</li>
                 ))}
               </ol>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}

          <p>If you use any other browser, the process should be similar to one of the above.</p>
        </>
      }
      highlightOkButton
      cancelText="Close and do not show again"
      okText="Close"
      onClose={onClose}
      onCancelClick={onCloseAndDoNotShow}
      onOkClick={onClose}
    />
  );
}

export default ThirdPartyCookiesDialog;
