import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import settings from '../settings';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    textAlign: 'center',
  },
}));

const footers = [
  {
    title: 'Company',
    items: [
      {
        name: 'Blog',
        url: 'https://blog.chessvision.ai',
      },
      {
        name: 'Contact us',
        url: 'mailto:pawel@chessvision.ai',
      },
    ],
  },
  {
    title: 'Features',
    items: [
      {
        name: 'Give us feedback',
        url: 'mailto:pawel@chessvision.ai',
      },
    ],
  },
  {
    title: 'Sites',
    items: [
      {
        name: 'FAQ',
        url: settings.routes.FAQ,
      },
      {
        name: 'Resources',
        url: settings.routes.RESOURCES,
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        name: 'Terms of Use',
        url: '/terms',
      },
    ]
  },
];

const Copyright = () => {
  return (
    <Typography variant="body1" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://chessvision.ai">
        chessvision.ai
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justify="space-evenly">
        {footers.map(footer => (
          <Grid item xs={6} sm={3} key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.items.map(item => (
                <li key={item.name}>
                  <Link href={item.url} variant="subtitle1" color="textSecondary">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Footer;
