import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  label: {
    marginTop: '0.6rem',
  },
  progress: {
    marginTop: '0.1rem',
  },
});

const ProgressBar = ({ determinate, text, progress }) => {
  const classes = useStyles();
  return (
    <div className="root">
      <Typography className={classes.label}>
        {text}
      </Typography>
      { determinate
        ? (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progress}
          />
        )
        : (
          <LinearProgress
            className={classes.progress}
          />
        )
      }
    </div>
  );
}

export default ProgressBar;
