import React from 'react';

import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: '1rem 2rem',
    fontSize: '1.124rem',
    textTransform: 'none',
    fontWeight: 500,
    marginTop: '2rem',
    marginLeft: 2,
    marginRight: 2,
  }
});

const CTA = ({ classes, text, onClick }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('xs'));
  return (
    <Button color="secondary" variant="contained" onClick={onClick} fullWidth={!desktop} className={classes.root}>
      {text || "Get Started"}
    </Button>
  );
};

export default withStyles(styles)(CTA);
