import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Board from './Board';

const cardWidth = 440;
const boardWidth = 248;

const styles = theme => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
  },
  cardItem: {
    height: '100%',
  },
  cardContent: {
    width: cardWidth,
  },
  topActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mainContainer: {
    display: 'flex',
  },
  boardContainer: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  infoContainer: {
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    textAlign: 'center',
  },
  turn: {
    fontSize: '1rem',
    textAlign: 'center',
  },
  comment: {
    marginTop: theme.spacing(4),
  },
  deleteButton: {
    marginTop: theme.spacing(3),
  },
});

class StudyEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      diagrams: this.props.data,
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    const { diagrams } = this.state;
    const { onStudyEdit } = this.props;
    onStudyEdit(diagrams);
  }

  deleteFenAtIndex = (idx) => {
    const { diagrams } = this.state;
    const newDiagrams = diagrams.filter((item, i) => i !== idx);
    this.setState({
      diagrams: newDiagrams,
    });
  }

  editCommentAtIndex = (idx, newComment) => {
    const { diagrams } = this.state;

    const { comment, ...rest } = diagrams[idx];
    const newDiagrams = [...diagrams];

    newDiagrams[idx] = {
      comment: newComment,
      ...rest,
    };

    this.setState({
      diagrams: newDiagrams,
    });
  }

  clearStudy = () => {
    this.setState({
      diagrams: [],
    });
  }

  renderItem = ({ fen, comment }, idx) => {
    const { classes } = this.props;
    const turn = fen.split('_')[1] === 'w' ? 'White' : 'Black';

    return (
      <Card raised className={classes.cardItem}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>Diagram {idx+1}</Typography>
          <div className={classes.mainContainer}>
            <div className={classes.boardContainer} style={{ width: boardWidth, height: boardWidth }}>
              <Board size={boardWidth} fen={fen} />
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.turn}>{turn} to play</Typography>
              <TextField 
                className={classes.comment}
                value={comment}
                label="comment"
                variant="outlined"
                multiline
                rows={4}
                maxRows={4}
                helperText="Optional comment"
                onChange={(e) => { this.editCommentAtIndex(idx, e.target.value)}}
              />
              <Button
                className={classes.deleteButton}
                onClick={() => this.deleteFenAtIndex(idx)}
                style={{ textTransform: 'none', minWidth: 'auto' }}
                startIcon={<DeleteIcon fontSize="small" />}
              >Delete from Study</Button>
            </div>
          </div>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }} disableSpacing>
        </CardActions>
      </Card>
    );
  }
  render() {
    const { classes } = this.props;
    const { diagrams } = this.state;

    return (
      <div className={classes.container}>
        { diagrams.length === 0 
        ? (
          <Typography variant="h4" style={{ textAlign: 'center' }}>No Diagrams</Typography>
        ) : (
          <>
            <div className={classes.topActions}>
              <Button onClick={() => this.clearStudy()}>Delete All</Button>
            </div>
            <List>
              { diagrams.map((item, idx) => (
                <ListItem key={`${item.fen}-${idx}`}>
                  {this.renderItem(item, idx)}
                </ListItem>
              ))}
            </List>
          </>
        )
        }
      </div>
    );
  }
};

export default withStyles(styles)(StudyEdit);
