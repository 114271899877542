import React from 'react';

import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProgressBar from './ProgressBar';
import Switch from '@material-ui/core/Switch';

import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


import { useMediaQuery } from 'react-responsive';

import { makeStyles } from '@material-ui/core/styles';

import settings from '../../settings';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: '0.5rem 0.5rem'
  },
  separator: {
    flex: 1,
  },
  iconStatus: {
    marginTop: '0.5rem',
    marginRight: '0.5rem',
  },
  textStatus: {
    marginTop: '0.5rem',
  },
  accessStatus: {
    marginRight: '2rem',
  },
}));

const iconForStatusType = type => {
  if (type === 'success') {
    return <SuccessIcon />;
  } else if (type === 'error') {
    return <ErrorIcon />;
  } else if (type === 'info') {
    return <InfoIcon />;
  } else if (type === 'restricted') {
    return <LockIcon />;
  }
}

const TopBar = ({
  status,
  analysisWindowOpen, toggleAnalysisWindow,
  accessStatusComponent,
  fullscreen, toggleFullscreen,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery({ query: `(max-width: ${settings.values.maxMobileWidth}px)` });

  return (
    <div className={classes.container}>
      { status && status.type && (
        <div className={classes.iconStatus}>
          {iconForStatusType(status.type)}
        </div>
      )}
      { status && status.running && (
        <ProgressBar
          determinate={status.progress !== null}
          text={status.text}
          progress={status.progress}
        />
      )}
      { status && !status.running && (
       <Typography className={classes.textStatus}>
         {status.text}
       </Typography>
      )}
      <div className={classes.separator}></div>
      <div className={classes.accessStatus}>
        {accessStatusComponent}
      </div>
      { !isMobile && (
        <FormControlLabel
           control={
            <Switch
              checked={fullscreen}
              onChange={() => toggleFullscreen(!fullscreen)}
              value="Fullscreen"
              color="primary"
            />
           }
           label="Fullscreen"
         />
      )}
      { isMobile && (
        <ButtonGroup color="primary" size="small" style={{ alignItems: 'flex-start' }}>
          <Button variant="contained" color={analysisWindowOpen ? "default" : "primary"} onClick={() => toggleAnalysisWindow(!analysisWindowOpen)}>Book</Button>
          <Button variant="contained" color={!analysisWindowOpen ? "default" : "primary"} onClick={() => toggleAnalysisWindow(!analysisWindowOpen)}>Board</Button>
        </ButtonGroup>
      )}
      { !isMobile && (
        <FormControlLabel
           control={
            <Switch
              checked={analysisWindowOpen}
              onChange={() => toggleAnalysisWindow(!analysisWindowOpen)}
              value="Analysis board"
              color="primary"
            />
           }
           label="Analysis board"
         />
     )}
    </div>
  );
}

export default TopBar;
