export const fensToLaTeX = (fens, comments, leftHeader="", rightHeader="", leftFooter="", enumerateFrom=1) => {
  if (!enumerateFrom) {
    enumerateFrom = 1;
  }

  let solutionSpace = String.raw`\begin{center}(#1).\dotfill\ifthenelse{\equal{#3}{w}}{$\square$}{$\blacksquare$}\end{center}`;

  let commentText = String.raw`#4`;

  let footerUrl = 'https://ebook.chessvision.ai'

  const truncate = (s, mx) => {
    const suf = '…';
    return s.length + suf.length > mx ? s.substr(0, mx-suf.length) + '…' : s;
  }

  let out = String.raw`\documentclass[a4paper, oneside]{article}
  \usepackage{graphicx}
  \usepackage[a4paper, left=1.5cm, right=1.5cm, top=1.75cm, bottom=1.75cm]{geometry}
  \usepackage{skak}
  \usepackage{tabularx}
  \usepackage{fancyhdr}
  \usepackage{hyperref}
  \usepackage{amssymb}
  \pagestyle{fancy}
  \fancyhf{}
  \lhead{${leftHeader}}
  \rhead{${rightHeader}}
  \lfoot{${leftFooter}}
  \rfoot{created in \href{${footerUrl}}{${footerUrl}}}
  \cfoot{\thepage}
  \begin{document}
  \setlength{\tabcolsep}{24pt}

  \newcommand{\diagram}[4]{
  {\centering ${commentText}\par}
  \vspace{2 mm}
  \fenboard{#2}
  \centerline{\scalebox{1.1}{\showboard}}
  ${solutionSpace}
  }

  \newcommand{\solution}[2]{
  \begin{flushleft}
  (#1) #2
  \end{flushleft}
  }

  \begin{center}`;

  const perPage = 6;
  const n = fens.length;
  for (let i = 0; i < n; ++i) {
    if (i % perPage === 0) {
      out += String.raw`
      \begin{tabularx}{1.0\textwidth} {
      >{\raggedright\arraybackslash}X
      >{\raggedright\arraybackslash}X }`;
    }
    const fen = fens[i];
    const turn = fen.split('_')[1];
    const comment = comments[i] ? truncate(comments[i], 80) : String.raw`\vspace*{0.125\baselineskip}`;
    out += String.raw`\diagram{${enumerateFrom+i}}{${fen.split('_').join(' ')}}{${turn}}{${comment}}`;
    if (i % 2 === 0) {
      out += String.raw` & `;
    } else {
      out += String.raw` \\ `;
    }
    if (((i+1) % perPage === 0) || ((i+1) === n)) {
      out += String.raw`\end{tabularx} `;
    }
    if (((i+1) % perPage === 0) && ((i+1) !== n)) {
      out += String.raw`\pagebreak `;
    }
  }

  out += String.raw`
  \end{center}
  \end{document}`;
  return out;
}

export const fensToPdfUrl = (fens, comments, leftHeader="", rightHeader="", leftFooter="", enumerateFrom=1) => {
  let latex = fensToLaTeX(fens, comments, leftHeader, rightHeader, leftFooter, enumerateFrom);
  latex = encodeURIComponent(latex);
  return `https://texlive2020.latexonline.cc/compile?text=${latex}`;
}
