import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import settings from '../../settings';

import logoSign from './../../assets/logo_sign.png';
import logoText from './../../assets/logo_text.png';

const styles = (theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '3rem',
    maxWidth: 1024,
    margin: '0 auto',
  },

  logoContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#fff',
  },
  logoSign: {
    height: '36px',
  },
  logoText: {
    marginLeft: '15px',
    marginTop: '-2px',
    marginRight: '15px',
    height: '36px',
  },

  links: {
    '& a': {
      textDecoration: 'none',
      color: 'white',
      marginLeft: '2rem',
      transitionDuration: '150ms',
      transitionProperty: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, font-weight',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '& a:hover': {
      color: '#d9d9d9',
    }
  },

  signContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },

  signUpButton: {
    marginRight: theme.spacing(1)
  },
});

class Bar extends Component {

  render() {
    // Styling
    const { classes } = this.props;

    const { links } = this.props;

    // Events
    const { onSignUpClick, onSignInClick, isPerformingAuthAction  } = this.props;

    return (
      <AppBar color="primary" position="fixed">
        <Toolbar variant="regular">
          <div className={classes.container}>
            <div className={classes.logoContainer}>
              <Link to={settings.routes.HOME} className={classes.logoWrapper}>
                <img src={logoSign} className={classes.logoSign} alt="chessvision.ai" />
                <Hidden only={['xs']}>
                  <img src={logoText} className={classes.logoText} alt="chessvision.ai" />
                </Hidden>
              </Link>
            </div>
            <Hidden only={['xs', 'sm']}>
              <div className={classes.links}>
                { links.map(link => (
                  <a href={link.url} key={link.title}>{link.title}</a>
                ))}
              </div>
            </Hidden>
            <div className={classes.signContainer}>
              <Button className={classes.signUpButton} color="secondary" disabled={isPerformingAuthAction} variant="contained" onClick={onSignUpClick}>Register</Button>
              <Button color="secondary" disabled={isPerformingAuthAction} variant="outlined" onClick={onSignInClick}>Sign In</Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.propTypes = {
  classes: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
  isPerformingAuthAction: PropTypes.bool.isRequired,
  onSignUpClick: PropTypes.func.isRequired,
  onSignInClick: PropTypes.func.isRequired,
};

Bar.defaultProps = {
  links: [],
};

export default withStyles(styles)(Bar);
