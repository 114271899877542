import React from 'react';

import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Video from './Video';

const Videos = ({ data, onClose, fullscreen }) => {
  const tsThresh = 60*5 // 5 minutes
  let results = [];
  for (const entry of data) {
    const timestamps = [];
    for (const ts of entry.timestamps) {
      if (timestamps.length === 0 || ts - timestamps[timestamps.length -1] > tsThresh) {
        timestamps.push(ts);
      }
    }
    for (const ts of timestamps) {
      results.push({
        video: entry.video,
        channel: entry.channel,
        timestamp: ts,
      });
    }
  }
  return (
    <div style={{ backgroundColor: 'white', height: `calc(100vh - ${fullscreen ? 3.4 : 6.9}rem)`, display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={onClose}
        startIcon={<ArrowBackIcon />}
        style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
      >Back to Analysis</Button>
      <div style={{ flex: 1, overflowY: 'auto', backgroundColor: '#f9f9f9', paddingBottom: '2rem' }}>
        {results.map(entry => (
          <Video key={`${entry.video.id}-${entry.timestamp}`} {...entry} />
        ))}
      </div>
    </div>
  );
}

export default Videos;
