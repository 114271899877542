const utm = (queryString) => {

  const params = new URLSearchParams(queryString);
  let results = {};

  params.forEach((value, key) => {
    if (key.substr(0, 4) === 'utm_') {
      const name = key.substr(4);
      results[name] = value;
    }
  });

  return results;
};

export default utm;
