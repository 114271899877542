import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

import Result from './Result';

const useStyles = makeStyles(theme => ({
  container: {
  },
  resultWrapper: {
    paddingBottom: '1rem',
  },
  empty: {
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  emptyHeader: {
    fontSize: '3rem',
    color: grey[900],
    fontWeight: 800,
    letterSpacing: '-0.025em',
  },
  emptyText: {
    fontSize: '1.25rem',
    maxWidth: '48rem',
    color: grey[500],
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.25rem',
  },
}));

const Results = ({ results }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { results.length === 0 && (
        <div className={classes.empty}>
          <Typography className={classes.emptyHeader}>
            No results
          </Typography>
          <Typography variant="body1" className={classes.emptyText}>
            Try different searches or add more books to the Reader
          </Typography>
        </div>
      )}
      { results.length > 0 && (
        <Grid container spacing={1}>
          { results.map(e => {
          return (
            <Grid key={e.id} item xs={12} lg={3} md={4}>
              <div className={classes.resultWrapper}>
                <Result {...e} />
              </div>
            </Grid>
          );
          })}
        </Grid>
      )}
    </div>

  );
}

export default Results;
