import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import openIcon from '../../assets/open_icon.png';
import relatedGames from '../../assets/related_games.png';
import oneTimePurchase from '../../assets/../assets/one_time_purchase.png';

import Footer from './../../Site/Footer';

import settings from '../../settings';

const styles = (theme) => ({
  faq: {
    paddingTop: '8rem',
    '& p': {
      color: grey[800],
      lineHeight: '1.8rem',
    },
    '& h3': {
      marginTop: '4rem',
    },
  },
  contentWrapper: {
    marginTop: '2rem',
  },
  openImg: {
    display: 'inline-block',
    marginBottom: '-8px',
  },
  relatedGamesImg: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
    maxWidth: '320px',
  },
  oneTimePurchaseImg: {
    display: 'inline-block',
    marginBottom: '-14px',
  },
  cookiesList: {
    '&>li': {
      listStyleType: 'decimal',
    }
  },
});


class FAQ extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const openImg = <img src={openIcon} alt="open document icon" className={classes.openImg} />;
    const relatedGamesImg = <img src={relatedGames} alt="related games" className={classes.relatedGamesImg} />;
    const oneTimePurchaseImg = <img src={oneTimePurchase} alt="one-time purchase" className={classes.oneTimePurchaseImg} />;
    const emailLink = <a href="mailto:hello@chessvision.ai">hello@chessvision.ai</a>;

    const faqs = [
      {
        question: 'How do I start working with my books?',
        answer: <>First, open your PDF, ePub, or DjVu book as you do in any other reader: Click on the orange icon {openImg} and browse your local disk to select an ebook you want to work with. Then, the Reader will ask you if you want to send the opened book for analysis. Click "Analyze" and wait until it's done. You can observe the progress on the status bar at the top. Once the book is processed, you will see a message that the File is ready and you can double-click on any diagram in the book to open it for analysis. After you do that, the position from the diagram will appear on the analysis board on the right.</>,
      },
      {
        question: 'Do I need to send a book for analysis every time I open it?',
        answer: 'No, that would be very painful. Every book is analyzed only the first time you open it. Next time you open an already processed book, it will be immediately ready to use.',
      },
      {
        question: 'What to do if I see an empty analysis board?',
        answer:
          <>
            <p>
              The Reader uses the Lichess analysis board, and embedding it requires the browser to have 3rd party cookies enabled for Lichess.
              If you don't have them enabled, you are now seeing an empty analysis board with no pieces on it.
              In order to fix it and use the Reader, follow the below instructions:
            </p>

            <b>Chrome</b>

            <ol className={classes.cookiesList}>
              <li>Open <b>Chrome preferences</b></li>
              <li>Select <b>Privacy and security</b></li>
              <li>Click <b>Cookies and other site data</b></li>
              <li>Find section <b>Sites that can always use cookies</b></li>
              <li>Add <b>lichess.org</b> there</li>
              <li>Refresh the page with the Reader</li>
            </ol>

            <b>Firefox</b>

            <ol className={classes.cookiesList}>
              <li>Open <b>Firefox preferences</b></li>
              <li>Select <b>Privacy & Security</b></li>
              <li>Find section <b>Cookies and Site Data</b></li>
              <li>Click <b>Manage exception...</b></li>
              <li>Add <b>lichess.org</b> as an <b>allowed exception</b></li>
              <li>Refresh the page with the Reader</li>
            </ol>

            <p>If you use any other browser, the process should be similar to one of the above.</p>
        </>
      },
      {
        question: 'Is there any way I can see a database of games with a position in the diagram?',
        answer: <>Yes, of course. If any games in a position from the diagram are found, there will be a link just above the analysis board to see the games in the chess.com games database. Just as shown in the picture below:
                    {relatedGamesImg}</>,
      },
      {
        question: 'Can I use it on my mobile device?',
        answer: <>Yes, of course. The best experience would be to download a Mobile app for the Reader. It is available for both: <a href={settings.values.appStoreUrl}>iOS</a> and <a href={settings.values.playStoreUrl}>Android</a> and it is designed to work both with phones and tablets to deliver the best reading experience.</>,
      },
      {
        question: 'It there an option to make a one-time payment to get lifetime access to a chosen book?',
        answer: <>Yes, there is. When you open your book, you will see a button like this one {oneTimePurchaseImg}. Click it and you will be redirected to checkout. The price is calculated based on the number of pages in the book. After the purchase, you will be granted unlimited lifetime access to this book.</>,
        showOnlyForOneTimePurchaseEnabled: true,
      },
      {
        question: 'Should I become a member or purchase analysis for a single book',
        answer: 'It depends on your needs really. One way to decide is to see how many books would you like to study with. If there are two or more books you plan to study with, then becoming a member sounds like a better option. On the other hand, if you plan to work with just a single book, then purchasing lifetime access to that book might be better.',
        showOnlyForOneTimePurchaseEnabled: true,
      },
      {
        question: 'I have an idea for additional functionality, what should I do?',
        answer: <>That's awesome. Email us at {emailLink}. We are more than happy to hear what could make your experience better!</>,
      }
    ];

    return (
      <div className={classes.root}>
        <div className={classes.faq}>
          <Container maxWidth="md">
            <Typography variant="h2" align="center">Frequently Asked Questions</Typography>
            <Typography align="center" style={{ marginTop: 30 }}>
              You can read full Tutorials here:
              <ul>
                <li>
                  - <a href="https://chessvision.ai/docs/ebook-reader/" target="_blank" rel="noopener noreferrer">Reader (Web version)</a>
                </li>
                <li>
                  - <a href="https://chessvision.ai/docs/ebook-reader-mobile/" target="_blank" rel="noopener noreferrer">Reader (Mobile app)</a>
                </li>
              </ul>
            </Typography>
            <div className={classes.contentWrapper}>
              { faqs.filter(e => !e.showOnlyForOneTimePurchaseEnabled || settings.values.oneTimePurchaseEnabled)
                .map((e, idx) => (
                  <ExpansionPanel key={e.question}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <h3>{idx+1}. {e.question}</h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <p>{e.answer}</p>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))
              }
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQ);
