import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  creations: {
    align: 'left',
    margin: 0,
    marginLeft: theme.spacing(4),
    padding: 0,
    '& li': {
      listStyle: 'disc',
    },
  },
  container: {
    lineHeight: theme.spacing(0.20),
    textAlign: 'left',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
  },
}));

const Personal = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p>
        My name is Pawel. There is something about applying technology to chess that gets me excited with every new creation. <br/>I founded <a href="https://chessvision.ai" target="_blank" rel="noopener noreferrer">chessvision.ai</a> in April 2019 and we launched the Reader in January 2020 in <a href="https://www.reddit.com/r/chess/comments/elvtdx/i_made_chess_ebook_reader_that_makes_chess_books/" target="_blank" rel="noopener noreferrer">this Reddit post</a>. You may already know some of my other work:
      </p>
      <ul className={classes.creations}>
        <li>
          <a href="https://chessvision.ai" target="_blank" rel="noopener noreferrer">chessvision.ai</a> apps,
          including <a href="https://chrome.google.com/webstore/detail/chessvisionai-chess-posit/johejpedmdkeiffkdaodgoipdjodhlld" target="_blank" rel="noopener noreferrer">Chrome extension</a> and <a href="https://addons.mozilla.org/en-US/firefox/addon/chessvision-ai-for-firefox/" target="_blank" rel="noopener noreferrer">Firefox extension</a>
        </li>
        <li>Beloved <a href="https://www.reddit.com/user/chessvision-ai-bot" target="_blank" rel="noopener noreferrer">u/chessvision-ai-bot</a> Reddit bot that helps to solve chess puzzles</li>
        <li>Automatic <a href="https://chrome.google.com/webstore/detail/chesscom-voice-commentary/kampphbbbggcjlepmgfogpkpembcaphk" target="_blank" rel="noopener noreferrer">Chess.com Voice Commentary</a>, see <i>Danny Rensch</i> showing it on <a href="https://www.youtube.com/watch?v=awcCisMs-qg" target="_blank" rel="noopener noreferrer">video</a></li>
        <li><a href="https://www.reddit.com/r/chess/comments/crqnlf/used_neural_networks_to_generate_chess_pieces/" target="_blank" rel="noopener noreferrer">Using Neural Network to generate chess pieces</a></li>
      </ul>
      <p>You can contact me with any questions or ideas you want to share <a href="mailto:pawel@chessvision.ai">pawel@chessvision.ai</a>. We can also talk about programming, data science, basketball or play chess online</p>
      <p>
        — <a href="https://pkacprzak.github.io" target="_blank" rel="noopener noreferrer">Pawel Kacprzak</a>, the author of chessvision.ai
      </p>
    </div>
  );
}

export default Personal;
