import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LaunchIcon from '@material-ui/icons/Launch';
import CopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';

// import Chessboard from 'chessboardjsx';
import 'chessboard-element';

import { makeStyles } from '@material-ui/core/styles';

import { truncate } from '../../utils';
import { emitEvent, eventTypes } from '../../events';

import settings from '../../settings';

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    textAlign: 'center',
    padding: '0.5rem 0.5rem',
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  titleWrapper: {
  },
  title: {
    textDecoration: 'none',
    color: '#030303',
  },
  infoContainer: {
    fontSize: '0.8rem',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
  boardWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  actions: {
    display: 'flex',
  },
  openButton: {
    marginRight: theme.spacing(1),
  },
}));

const Result = (diagram) => {
  const classes = useStyles();

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const openSnackbar = () => { setSnackbarOpen(true) }
  const closeSnackbar = () => { setSnackbarOpen(false) }

  const copyFenTopClipboard = (fen) => {
    const e = document.createElement("textarea");
    e.innerHTML = fen;
    document.body.appendChild(e);
    e.select();
    try {
      document.execCommand('copy');
      e.focus();
      openSnackbar();
    } catch (err) {
      alert('Unable to copy to clipboard, please select and copy manually.')
    } finally {
      e.remove();
    }
  }

  const {
    analysis_id, analysis_title: title, analysis_page: page,
    analysis_checksum: checksum, position
  } = diagram;

  const handleOpen = () => {
    window.localStorage.setItem(settings.values.newTabRemoteFileKey, JSON.stringify({
      id: analysis_id,
      title,
      checksum,
      page: page+1,
    }));
    emitEvent(eventTypes.searchResultOpen);
    window.open(settings.routes.HOME, '_blank').focus();
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.titleWrapper}>
          <Tooltip title={title} enterDelay={500}>
            <span className={classes.title}>{truncate(title, 28)}</span>
          </Tooltip>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.subtitle}>
            <span className={classes.timestamp}>at page {page+1}</span>
          </div>
        </div>
        <div className={classes.boardWrapper}>
          <chess-board
            hide-notation
            position={position} style={{ width: "226px" }}
          />
        </div>
        <div className={classes.actions}>
          <Button
            size="small"
            color="default"
            variant="outlined"
            fullWidth
            onClick={() => { handleOpen() }}
            endIcon={<LaunchIcon />}
            className={classes.openButton}
          >Open</Button>
          <Tooltip title="Copy FEN" enterDelay={500}>
            <IconButton
              size="small"
              color="default"
              variant="outlined"
              onClick={() => copyFenTopClipboard(position)}
            ><CopyIcon /></IconButton>
          </Tooltip>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        message="FEN copied to clipboard!"
        severity="success"
      />
    </>
  );
}

export default Result;
