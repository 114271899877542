import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PropTypes from 'prop-types';

// Material-UI
import { MuiThemeProvider } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';

import validate from 'validate.js';
import readingTime from 'reading-time';

import settings from '../settings';
import { eventTypes, emitEvent } from '../events';

import constraints from '../constraints';

import Bar from '../content/LandingContent/Bar';

import LandingContent from '../content/LandingContent/LandingContent';
import TermsContent from '../content/LandingContent/TermsContent';
import NotFoundContent from '../content/NotFoundContent/NotFoundContent';
import FAQ from '../content/FAQContent/FAQ';
import Resources from '../content/ResourcesContent/Resources';

import SignUpDialog from '../dialogs/SignUpDialog/SignUpDialog';
import SignInDialog from '../dialogs/SignInDialog/SignInDialog';
import ResetPasswordDialog from '../dialogs/ResetPasswordDialog/ResetPasswordDialog';

import theme from './theme';

const links = [
  {
    title: 'How it works?',
    url: '#description',
  },
  {
    title: 'Reviews',
    url: '#proof',
  },
  {
    title: 'Pricing',
    url: '#pricing',
  },
];

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPerformingAuthAction: false,

      signUpDialog: {
        open: false
      },

      signInDialog: {
        open: false
      },

      resetPasswordDialog: {
        open: false
      },

      snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false
      }
    };
  }

  signUp = (emailAddress, password, passwordConfirmation) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress || !password || !passwordConfirmation) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress,
      password: password,
      passwordConfirmation: passwordConfirmation
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password,
      passwordConfirmation: constraints.passwordConfirmation
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      auth.createUserWithEmailAndPassword(emailAddress, password).then((value) => {
        emitEvent(eventTypes.signUpEmail);
        const { onNewAccountCreated } = this.props;
        onNewAccountCreated(value.user);
        this.closeSignUpDialog(() => {
          this.setState({
            isPerformingAuthAction: false
          });
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/email-already-in-use':
          case 'auth/invalid-email':
          case 'auth/operation-not-allowed':
          case 'auth/weak-password':
            this.openSnackbar(message);
            return;

          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  signIn = (emailAddress, password) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress || !password) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress,
      password: password,
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      auth.signInWithEmailAndPassword(emailAddress, password).then((value) => {
        this.closeSignInDialog(() => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          emitEvent(eventTypes.signInEmail);

          this.openSnackbar(`Signed in as ${displayName || emailAddress}`);
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':
          case 'auth/wrong-password':
            this.openSnackbar(message);
            return;

          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  signInWithProvider = (provider) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!provider) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      auth.signInWithPopup(provider).then((value) => {
        if (value.additionalUserInfo) {
          const { isNewUser } = value.additionalUserInfo;
          if (isNewUser) {
            emitEvent(eventTypes.signUpGoogle);
            const { onNewAccountCreated } = this.props;
            onNewAccountCreated(value.user);
          } else {
            emitEvent(eventTypes.signInGoogle);
          }
        }
        this.closeSignUpDialog(() => {
          this.closeSignInDialog(() => {
            const user = value.user;
            const displayName = user.displayName;
            const emailAddress = user.email;

            this.openSnackbar(`Signed in as ${displayName || emailAddress}`);
          });
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/account-exists-with-different-credential':
          case 'auth/auth-domain-config-required':
          case 'auth/cancelled-popup-request':
          case 'auth/operation-not-allowed':
          case 'auth/operation-not-supported-in-this-environment':
          case 'auth/popup-blocked':
          case 'auth/popup-closed-by-user':
          case 'auth/unauthorized-domain':
            this.openSnackbar(message);
            return;

          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  resetPassword = (emailAddress) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress
    }, {
      emailAddress: constraints.emailAddress
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      auth.sendPasswordResetEmail(emailAddress).then(() => {
        this.closeResetPasswordDialog(() => {
          this.openSnackbar(`Password reset e-mail sent to ${emailAddress}`);
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/invalid-email':
          case 'auth/missing-android-pkg-name':
          case 'auth/missing-continue-uri':
          case 'auth/missing-ios-bundle-id':
          case 'auth/invalid-continue-uri':
          case 'auth/unauthorized-continue-uri':
          case 'auth/user-not-found':
            this.openSnackbar(message);
            return;

          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  openSignUpDialog = () => {
    this.setState({
      signUpDialog: {
        open: true
      },
      signInDialog: {
        open: false,
      }
    });
  };

  closeSignUpDialog = (callback) => {
    this.setState({
      signUpDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openSignInDialog = () => {
    this.setState({
      signInDialog: {
        open: true
      },
      signUpDialog: {
        open: false
      },
    });
  };

  closeSignInDialog = (callback) => {
    this.setState({
      signInDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openResetPasswordDialog = () => {
    this.setState({
      resetPasswordDialog: {
        open: true
      }
    });
  };

  closeResetPasswordDialog = (callback) => {
    this.setState({
      resetPasswordDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  render() {
    const { cookies } = this.props;

    const {
      isPerformingAuthAction,
      signUpDialog, signInDialog, resetPasswordDialog,
      snackbar,
    } = this.state;

    const HomeBar = (
      <Bar
        onSignUpClick={this.openSignUpDialog}
        onSignInClick={this.openSignInDialog}
        isPerformingAuthAction={isPerformingAuthAction}
        links={links}
      />
    );
    const BarWithoutLinks = (
      <Bar
        onSignUpClick={this.openSignUpDialog}
        onSignInClick={this.openSignInDialog}
        isPerformingAuthAction={isPerformingAuthAction}
        />
    );

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route path={settings.routes.HOME} exact>
              {HomeBar}
              <LandingContent
                cookies={cookies}
                onSignUpClick={this.openSignUpDialog}
              />
            </Route>
            <Route path={settings.routes.FAQ} exact>
              {BarWithoutLinks}
              <FAQ />
            </Route>
            <Route path={settings.routes.RESOURCES} exact>
              {BarWithoutLinks}
              <Resources />
            </Route>
            <Route path={settings.routes.TERMS} exact>
              {BarWithoutLinks}
              <TermsContent />
            </Route>
            <Route>
              {BarWithoutLinks}
              <NotFoundContent />
            </Route>
          </Switch>
        </Router>

        <React.Fragment>
          <Hidden only="xs">
            <SignUpDialog
              open={signUpDialog.open}

              isPerformingAuthAction={isPerformingAuthAction}

              signUp={this.signUp}
              onSignInClick={this.openSignInDialog}

              onClose={this.closeSignUpDialog}
              onAuthProviderClick={this.signInWithProvider}
            />

            <SignInDialog
              open={signInDialog.open}

              isPerformingAuthAction={isPerformingAuthAction}

              signIn={this.signIn}
              onSignUpClick={this.openSignUpDialog}

              onClose={this.closeSignInDialog}
              onAuthProviderClick={this.signInWithProvider}
              onResetPasswordClick={this.openResetPasswordDialog}
            />
          </Hidden>

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <SignUpDialog
              fullScreen
              open={signUpDialog.open}

              isPerformingAuthAction={isPerformingAuthAction}

              signUp={this.signUp}
              onSignInClick={this.openSignInDialog}

              onClose={this.closeSignUpDialog}
              onAuthProviderClick={this.signInWithProvider}
            />

            <SignInDialog
              fullScreen
              open={signInDialog.open}

              isPerformingAuthAction={isPerformingAuthAction}

              signIn={this.signIn}
              onSignUpClick={this.openSignUpDialog}

              onClose={this.closeSignInDialog}
              onAuthProviderClick={this.signInWithProvider}
              onResetPasswordClick={this.openResetPasswordDialog}
            />
          </Hidden>

          <ResetPasswordDialog
            open={resetPasswordDialog.open}

            isPerformingAuthAction={isPerformingAuthAction}

            resetPassword={this.resetPassword}

            onClose={this.closeResetPasswordDialog}
          />
        </React.Fragment>
        <Snackbar
          autoHideDuration={snackbar.autoHideDuration}
          message={snackbar.message}
          open={snackbar.open}
          onClose={this.closeSnackbar}
        />
      </MuiThemeProvider>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Landing;
