const truncate = (str, len) => {
  const suf = "...";
  if (str.length + suf.length <= len) {
    return str;
  }
  return str.slice(0, Math.max(0, len-suf.length)) + '...';
}

const dateFromInt = dateInt => {
  // from format YYYYMMDD
  const day = dateInt % 100;
  const month = Math.floor(dateInt / 100) % 100;
  const year = Math.floor(dateInt / 10000);
  return new Date(year, month, day);
};

const nFormatter = (num, digits) => {
  // implementation source: https://stackoverflow.com/a/9462382/948918
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; --i) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const toTitleCase = (phrase) => {
  // implementation source: https://stackoverflow.com/a/43376967/948918
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export {
  truncate,
  dateFromInt,
  nFormatter,
  toTitleCase,
};
