import React from 'react';

import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  readerWrapper: {
    position: 'relative',
  },
  cover: {
    display: theme.name === 'dark' ? 'block' : 'none',
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#888',
    // mixBlendMode: 'difference',
    // mixBlendMode: 'darken', // looks ok
    mixBlendMode: 'multiply', // looks ok
    // mixBlendMode: 'hard-light', // looks ok
    // mixBlendMode: 'luminosity', // looks ok
    zIndex: 1,
    // opacity: 0.75,
    // backgroundColor: '#67645e',
    // backgroundColor: '#303030',
    // backgroundColor: '#a39b8b',
  },
});

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    window.handlePageClick = this.props.handlePageClick;
    window.handleFileChange = (file) => {
      /*
      the file is copied here because for some reason, firebase throws error
      when original file is uploaded; it might be possible that the original
      file object is mutated later by pdfjs viewer.js in such a way that it
      prevents it from being eligible to upload on firebase
      */
      const copy = new File([file], file.name, { type: file.type });
      this.props.handleFileChange(copy);
    };
  }

  componentDidMount() {
    const element = this.viewerRef.current;

    const iframe = document.createElement('iframe');
    iframe.setAttribute("id", "iframe");

    iframe.src = '/pdfjs-2.2.228-dist/web/viewer.html';
    iframe.width = '100%';
    iframe.height = '100%';

    const { remoteFile } = this.props;
    if (remoteFile !== null) {
      this.openRemoteFile();
    }
    element.appendChild(iframe);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remoteFile !== this.props.remoteFile) {
      this.openRemoteFile();
    }
  }

  openRemoteFile() {
    const { remoteFile } = this.props;
    if (remoteFile !== null) {
      let iframe = document.getElementById('iframe');
      if (iframe === null) {
        iframe = document.createElement('iframe');
      }
      let src = '/pdfjs-2.2.228-dist/web/viewer.html';
      src += `?file=${remoteFile.url}`;
      src += `&title=${remoteFile.title}`;
      iframe.src = src;
      if (remoteFile.page) {
        iframe.onload = function() {
          // using this solution to open on a specific page
          // https://stackoverflow.com/questions/14798342/loading-pdf-at-a-specific-page-with-pdf-js-viewer
          const pdfApp = iframe.contentWindow.PDFViewerApplication;
          pdfApp.initialBookmark = `page=${remoteFile.page}`;
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { fullscreen } = this.props;
    return (
      <div className={classes.readerWrapper} ref={this.viewerRef} id='viewer'
        style={{ width: 'auto', height: `calc(100vh - ${fullscreen ? 3.4 : 6.9}rem)` }}>
        <div className={classes.cover}></div>
      </div>
    )
  }
}

export default withStyles(styles)(PDFViewer);
