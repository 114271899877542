import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import OpenIcon from '../../assets/../assets/open_icon_transparent.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import settings from '../../settings';
import { emitEvent, eventTypes } from '../../events';
import { IconButton } from '@material-ui/core';
import { AndroidButton, AppleButton } from '../../buttons/MobileButton';

import { isFreeBookHidden, hideFreeBook } from './utils';

import MediaQuery from 'react-responsive';

const useStyles = makeStyles(theme => ({
  items: {
    marginTop: theme.spacing(3),
  },
  item: {
    textDecoration: 'none',
    '& a': {
      textDecoration: 'none',
    },
  },
  card: {
    position: 'relative',
  },
  bannerClose: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  cardBanner: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    '& a': {
      color: theme.palette.secondary.contrastText,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  titleMobile: {
    marginBottom: theme.spacing(1),
  },
  actions: { 
    marginRight: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
  },
  actionsMobile: {},
  openIcon: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2px 5px',
    borderRadius: '25%',
    verticalAlign: 'bottom',
  },
  loading: {
    marginRight: theme.spacing(1),
  },
}));

const freeBookTile = {
  checksum: 'd72426a7a689532c55bd258260a17d96',
  title: 'Claim a FREE book "18 mates in 2 from r/chess"',
  mobileTitle: 'Free "18 mates in 2 from r/chess"',
  description: <>
    Claim an ebook featuring 18 cool mate in 2 puzzles collected from r/chess subreddit. We created it especially for the interactive eBook Reader experience.
  </>,
  descriptionMobile: <>
    Claim an eBook with 18 cool mates in 2 collected from r/chess subreddit
  </>,
}

export default function Tiles({ onTutorialsClick, onGetBookClick, bookLoading, isMobile }) {
  const classes = useStyles();

  const initFreeBookBannerHidden = isFreeBookHidden(freeBookTile.checksum);
  
  const [freeBookBannerHidden, setFreeBookBannerHidden] = useState(initFreeBookBannerHidden);

  const hideFreeBookBanner = (checksum) => {
    setFreeBookBannerHidden(true);
    hideFreeBook(checksum);
  }

  const banners = [
    // Say Chess book
    // {
    //   onClose: hideSayChessBanner,
    //   title: "Get a FREE eBook featuring Capablanca's Games",
    //   titleMobile: "FREE Capablanca eBook",
    //   description: <>
    //     Receive an eBook containing 14 annotated games by Capablanca, published by author <a 
    //       href="https://saychess.substack.com/about"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       onClick={() => emitEvent(eventTypes.sayChessTilesAboutClick)}
    //       >Martin B. Justesen </a>
    //       and generously provided for our users
    //   </>,
    //   descriptionMobile: <>
    //     Containing 14 annotated games by Capablanca, written by <a 
    //       href="https://saychess.substack.com/about"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       onClick={() => emitEvent(eventTypes.sayChessTilesAboutClick)}
    //       >Martin B. Justesen </a>
    //       and generously provided for our users
    //   </>,
    //   links: [
    //     {
    //       title: "Get Your FREE Copy",
    //       onClick: () => { emitEvent(eventTypes.sayChessTilesGetBookClick); onGetBookClick('bd09f7c6d9e3bd7c02ae0d6942c99e49')},
    //       disabled: bookLoading,
    //       loading: bookLoading,
    //       style: {
    //         backgroundColor: 'white',
    //       },
    //     },
    //     {
    //       title: "See Martin's Newsletter",
    //       href: "https://saychess.substack.com/",
    //       color: "primary",
    //       variant: "outlined",
    //       onClick: () => emitEvent(eventTypes.sayChessTilesCTAClick),
    //       external: true,
    //       style: {
    //         borderColor: 'white',
    //       },
    //     }
    //   ],
    // },
    // 18 mates in 2 from r/chess book by Chessvision.ai
    {
      onClose: () => hideFreeBookBanner(freeBookTile.checksum),
      title: freeBookTile.title,
      titleMobile: freeBookTile.mobileTitle,
      description: freeBookTile.description,
      descriptionMobile: freeBookTile.descriptionMobile,
      links: [
        {
          title: "Get Your FREE Copy",
          onClick: () => { emitEvent(eventTypes.tilesClaimFreeBookClick, {checksum: freeBookTile.checksum}); onGetBookClick(freeBookTile.checksum)},
          disabled: bookLoading,
          loading: bookLoading,
          style: {
            backgroundColor: 'white',
          },
        },
      ],
    },

  ];

  const items = [
    {
      title: 'Open a PDF, ePub, or DjVu',
      description: <>
        Click the <img src={OpenIcon} alt="open icon" className={classes.openIcon} /> button
        above to open a file from your local storage. If you don't have any chess books to try,
        you can find many <a
          href="https://www.qualitychess.co.uk/pdf.htm"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => emitEvent(eventTypes.samplePdfsOpenTiles)}
          >free samples here</a>.
      </>,
    },
    {
      mediaQueryMaxWidth: 851,
      title: "Get for iOS and Android",
      description: <>
        Now on iOS and Android — enjoy a better mobile experience, anywhere.
      </>,
      links: [
        ({ style = {} }) => (
          <AppleButton 
            size="small"
            onClickEventType={eventTypes.tilesIosClick} 
            style={style}
          />
        ),
        ({ style = {} }) => (
          <AndroidButton 
            size="small"
            onClickEventType={eventTypes.tilesAndroidClick} 
            style={style}
          />
        ),
      ],
    },
    {
      title: 'My Documents',
      description: <>
        You can access all your uploaded books
        from the <b>My Documents</b> page. It acts as cloud storage so all
        your documents are available across all your devices.
      </>,
      links: [
        {
          title: 'My Documents',
          route: settings.routes.DOCUMENTS,
          onClick: () => emitEvent(eventTypes.documentsOpenTiles),
        },
      ],
    },
    {
      title: 'Diagram Search',
      description: <>
        Search Diagrams in your books.
        Define one or more filters and chess themes.
        The results point to the exact pages where the diagrams appear in your books.
      </>,
      links: [
        {
          title: 'Diagrams Search',
          route: settings.routes.SEARCH,
          onClick: () => emitEvent(eventTypes.diagramSearchOpenTiles),
        },
      ],
    },
    {
      title: 'Video Tutorials',
      description: 'Learn about all the functions by watching short video tutorials. For example, how to export selected diagrams to a PGN or PDF.',
      links: [
        {
          title: 'Video Tutorials',
          onClick: () => onTutorialsClick(eventTypes.videoTutorialsDialogOpenTiles),
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3} className={classes.items}>
    { !freeBookBannerHidden && banners.map(({ title, titleMobile, description, descriptionMobile, links, onClose }) => (
      <Grid key={title} item xs={12} className={classes.tile}>
        <Card variant="outlined" className={[classes.card, classes.cardBanner].join(' ')} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <IconButton 
            size="small"
            onClick={onClose}
            className={classes.bannerClose}
          >
            <CloseIcon style={{ color: 'white', fontSize: '1.25rem' }} 
          />
          </IconButton>
          <CardContent>
            <Typography variant="h6" className={isMobile ? classes.titleMobile : classes.title}>{isMobile ? titleMobile : title}</Typography>
            <Typography variant="body2">{isMobile ? descriptionMobile : description}</Typography>
          </CardContent>
          <CardActions style={{ flexWrap: 'wrap' }} className={isMobile ? classes.actionsMobile : classes.actions }>
            { links && (
              links.map(link => (
                <Button
                  component={link.route ? Link : (link.href ? "a" : "div")}
                  size="small"
                  href={link.href}
                  to={link.route}
                  onClick={link.onClick}
                  style={{ ...(link.style || {}), textTransform: 'none', marginLeft: isMobile ? 0 : 8, marginBottom: isMobile ? 4 : 0 }}
                  variant={link.variant || "contained"}
                  color={link.color || "white"}
                  key={link.title}
                  target={link.external ? "_blank" : "_self"}
                  rel={link.external ? "noopener noreferrer" : ""}
                  disabled={link.disabled}
                  fullWidth
                >
                  { link.loading && <CircularProgress size={20} className={classes.loading} /> }
                  {link.title}</Button>
              ))
            )}
          </CardActions>
        </Card>
      </Grid>
    ))}
    
    { items.map(({ title, description, links, mediaQueryMaxWidth = 999999999 }) => (
      <MediaQuery key={title} maxWidth={mediaQueryMaxWidth}>
        <Grid item xs={12} sm={6} md={6} className={classes.tile}>
          <Card variant="outlined" className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.title}>{title}</Typography>
              <Typography variant="body2">{description}</Typography>
            </CardContent>
            <CardActions style={{ flexWrap: 'wrap', justifyContent: links?.length > 1 ? 'space-evenly' : 'flex-start' }}>
              { links && (
                links.map(link => (
                  typeof link === 'function'
                  ? link({ style: { marginTop: 3, marginLeft: 0 }})
                  : (
                    <Button
                      component={link.route ? Link : (link.href ? "a" : "div")}
                      size="small"
                      href={link.href}
                      to={link.route}
                      onClick={link.onClick}
                      style={{ textTransform: 'none', marginLeft: isMobile ? 0 : 8, marginBottom: isMobile ? 4 : 0 }}
                      variant={link.variant || "contained"}
                      color={link.color || "secondary"}
                      key={link.title}
                      target={link.external ? "_blank" : "_self"}
                      rel={link.external ? "noopener noreferrer" : ""}
                      disabled={link.disabled}
                      fullWidth={isMobile}
                    >
                      { link.loading && <CircularProgress size={20} className={classes.loading} /> }
                      {link.title}</Button>
                  )
                ))
              )}
            </CardActions>
          </Card>
        </Grid>
      </MediaQuery>
    ))}
    </Grid>
  );
}
