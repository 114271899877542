import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import gray from '@material-ui/core/colors/grey';

const styles = (theme) => ({
  buttonDisabled: {
    backgroundColor: `${gray[300]} !important`,
  },
  progress: {
    marginRight: theme.spacing(1),
  }
});

class ConfirmationDialog extends Component {
  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (((typeof this.props.onOkClick) === 'function') && key === 'Enter') {
      this.props.onOkClick();
    }
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { open } = this.props;

    // Custom Properties
    const {
      title,
      content,
      cancelText,
      okText,
      disableOkButton,
      disableCancelButton,
      highlightOkButton,
      highlightCancelButton,
      isPerformingOkAction,
      children,
    } = this.props;

    // Dialog Events
    const { onClose } = this.props;

    // Custom Events
    const { onCancelClick, onOkClick } = this.props;

    if (!onClose) {
      return null;
    }

    return (
      <Dialog open={open} onClose={onClose} onKeyPress={this.handleKeyPress}>
        {title && <DialogTitle>{title}</DialogTitle>}

        {(content || children) && (
          <DialogContent>
            {content && (
            <DialogContentText>{content}</DialogContentText>
            )}
            {children}
          </DialogContent>
        )}

        {(onCancelClick || onOkClick) &&
          <DialogActions>
            {onCancelClick &&
              <Button color="primary" disabled={disableCancelButton} variant={highlightCancelButton && 'contained'} onClick={onCancelClick}>
                {cancelText || 'Cancel'}
              </Button>
            }

            {onOkClick &&
              <Button
                color="primary"
                disabled={isPerformingOkAction || disableOkButton}
                variant={highlightOkButton && 'contained'}
                onClick={onOkClick}
                classes={{ root: classes.button, disabled: classes.buttonDisabled }}
              >
                { isPerformingOkAction && <CircularProgress size={20} className={classes.progress} /> }
                {okText || 'OK'}
              </Button>
            }
          </DialogActions>
        }
      </Dialog>
    );
  }
}

ConfirmationDialog.defaultProps = {
  isPerformingOkAction: false,
  content: null,
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  disableOkButton: PropTypes.bool,
  disableCancelButton: PropTypes.bool,
  highlightOkButton: PropTypes.bool,
  highlightCancelButton: PropTypes.bool,
  isPerformingOkAction: PropTypes.bool,

  onClose: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  onOkClick: PropTypes.func
};

export default withStyles(styles)(ConfirmationDialog);
