import React, { Component } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmationDialog from '../../dialogs/ConfirmationDialog/ConfirmationDialog';
import ThirdPartyCookiesDialog from '../../dialogs/ThirdPartyCookiesDialog/ThirdPartyCookiesDialog';
import lichessMenu from '../../assets/lichess_menu.png';
import lichessEngineSwitch from '../../assets/lichess_engine_switch.png';
import lichessMultipleLines from '../../assets/lichess_multiple_lines.png';
import RelatedChessableCourses from './RelatedChessableCourses';
import RelatedGames from './RelatedGames';
import RelatedVideos from './RelatedVideos';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import CopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/EditOutlined';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CommentIcon from '@material-ui/icons/Comment';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import RotateIcon from '@material-ui/icons/Cached';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Badge from '@material-ui/core/Badge';
import PlayerSwitch from './PlayerSwitch';
import {
  getPlayerFromFen, startingFen,
  getLichessAnalysisUrl, getChesscomAnalysisUrl, getAnalysisBoardUrl,
} from './utils';

import { withStyles } from '@material-ui/styles';

import settings from '../../settings';
import { eventTypes, emitEvent } from '../../events';

const styles = theme => ({
  container: {
    overflow: 'hidden',
    height: '100%',
  },
  studyContainer: {
    background: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    overflow: 'hidden',
    zIndex: 999,
    position: 'relative',
  },
  studyMenu: {
    display: 'flex',
    '& Button': {
      marginRight: '0.5rem',
    },
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  topBar: {
    background: 'white',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 999,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
  },
  topBarOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  studySwitchForm: {
    marginRight: '0.2rem',
  },
  toolbarIcons: {
    float: 'right',
    paddingTop: '0.25rem',
  },
  playerSwitch: {
    "& .MuiFormControlLabel-label": {
      lineHeight: '1rem',
      maxWidth: 60,
      fontSize: '0.9rem',
    },
    marginRight: '0rem',
  },
  fenCopyButton: {
    marginRight: '0.2rem',
  },
  editButton: {
    marginRight: '0.2rem',
  },
  commentButton: {
    marginRight: '0.2rem',
  },
  setupButton: {
  },
  setupDialogImg: {
    display: 'inline',
    verticalAlign: 'middle',
  },
  relatedContainer: {
    flex: 1,
    verticalAlign: 'middle',
  },
  relatedWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  commentContainer: {
    background: 'white',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 999,
    paddingTop: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
  },
  lichessIframe: {
    display: 'block',
    width: '100%',
    height: '100vh',
    border: 'none',
    overflow: 'hidden',
    marginTop: '-60px',
    '@media (max-height: 600px)': {
      marginTop: '-40px',
    },

  },
});



class BoardAnalysis extends Component {

  constructor(props) {
    super(props);
    const { isMobile } = this.props;
    this.state = {
      setupDialog: {
        open: false,
      },
      advancedOpen: isMobile ? false : true,
      fenCopiedOpen: false,
      studyMenuOpen:  !!JSON.parse(window.localStorage.getItem(settings.values.studyCreatorOpenKey)),
      commentOpen: false,
      thirdPartyCookiesEnabled: undefined,
      thirdPartyCookiesDialogOpen: false,
      doNotShowCookiesMessage: undefined,

      shareFenMenu: null,

      pov: this.props.pov || 'white',  // the initial pov is either the pov given as a prop, or if this is null, then default is white
    };
    this.handlePlayerChange = this.handlePlayerChange.bind(this);
  }

  // using https://github.com/mindmup/3rdpartycookiecheck
  // the idea is that we detect if the browser is blocking ALL 3rd party cookies
  // by querying our cookie checking site
  // We do that because we cannot detect it for Lichess especially
  // If the browser blocks our 3rd party cookies, then we display a message
  // that the user can close, or close and make it not show again by setting
  // a localStorage flag
  handleCookiesMessage = (e) => {
    if (e.data === 'MM:3PCunsupported') {
      this.setState({
        thirdPartyCookiesEnabled: false,
        thirdPartyCookiesDialogOpen: true,
      });
    } else if (e.data === 'MM:3PCsupported') {
      this.setState({
        thirdPartyCookiesEnabled: true,
      });
    }
  }

  componentDidMount() {
    const doNotShowCookiesMessage = localStorage.getItem('chessvision/do-not-show-3rd-party-cookies-message-1') || false;
    this.setState({
      doNotShowCookiesMessage: JSON.parse(doNotShowCookiesMessage),
    });
    window.addEventListener("message", this.handleCookiesMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleCookiesMessage, false);
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.pov && nextProps.pov !== this.state.pov) {
      this.setState({
        pov: nextProps.pov,
      });
    }
  }

  closeCookiesDialog = (doNotShowCookiesMessage) => {
    emitEvent(eventTypes.closeThirdPartyCookiesDialog);
    if (doNotShowCookiesMessage) {
      emitEvent(eventTypes.doNotShowThirdPartyCookiesDialog);
      localStorage.setItem('chessvision/do-not-show-3rd-party-cookies-message-1', JSON.stringify(true));
    }
    this.setState({
      thirdPartyCookiesDialogOpen: false,
    });
  }

  handlePlayerChange = (e) => {
    const { onPlayerChange } = this.props;
    const currentPlayer = e.target.value === 'white' ? 'black' : 'white';
    onPlayerChange(currentPlayer);
  }

  handlePovChange = (e) => {
    const { pov } = this.state;
    // NOTE: this is in case we want to reflect the change in the parent, i.e. if we ever want to update the pov on the server upon change
    // this.props.onPovChange(pov === "white" ? "black" : "white")}}
    this.setState({
      pov: pov === "white" ? "black" : "white"
    });
  }

  openSetupDialog = () => {
    this.setState({
      setupDialog: {
        open: true
      }
    });
  };

  closeSetupDialog = (callback) => {
    this.setState({
      setupDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  studyMenuToggle = () => {
    const { studyMenuOpen } = this.state;
    const newOpenValue = !studyMenuOpen;
    this.setState({
      studyMenuOpen: newOpenValue,
    }, () => {
      window.localStorage.setItem(settings.values.studyCreatorOpenKey, JSON.stringify(newOpenValue));
      if (newOpenValue) {
        emitEvent(eventTypes.studyCreatorOpen);
      }
    });
  }

  handleToggleComment = () => {
    const { commentOpen } = this.state;
    this.setState({
      commentOpen: !commentOpen,
    }, () => {
      if (!commentOpen) {
        emitEvent(eventTypes.diagramCommentOpen);
      }
    });
  }

  handleToggleAdvanced = () => {
    const { advancedOpen } = this.state;
    this.setState({
      advancedOpen: !advancedOpen,
    });
  }

  render() {
    // styles
    const { classes } = this.props;

    const { isMobile } = this.props;
    const { user, fen } = this.props;
    const { comment } = this.props;

    const { advancedOpen } = this.state;
    const { setupDialog } = this.state;
    const { studyMenuOpen } = this.state;
    const { commentOpen } = this.state;
    const { fenCopiedOpen } = this.state;
    const { thirdPartyCookiesEnabled, thirdPartyCookiesDialogOpen, doNotShowCookiesMessage } = this.state;

    const { studySize, onStudyClear, onStudyAdd, onStudyEdit, onStudyExport } = this.props;
    const { onOpenEdit } = this.props;
    const { onCommentChange } = this.props;
    const { onRestorePosition } = this.props;

    const { onOpenVideos } = this.props;

    const { shareFenMenu } = this.state;

    const lichessMenuImg = <img src={lichessMenu} alt="menu button" className={classes.setupDialogImg} />;
    const lichessEngineSwitchImg = <img src={lichessEngineSwitch} alt="engine on/off switch" className={classes.setupDialogImg} />;
    const lichessMultipleLinesImg = <img src={lichessMultipleLines} alt="multiple lines option" className={classes.setupDialogImg} />;

    const currentPlayer = getPlayerFromFen(fen);

    const { pov } = this.state;

    const setupOptions = [
      {
        title: 'Turn engine on/off',
        text: <>Turn the engine on/off at any time using the engine switch button {lichessEngineSwitchImg}</>,
      },
      {
        title: 'Change whose turn is it to move',
        text: `Click on "White's turn" switch to toggle between White/Black to move.`,
      },
      {
        title: 'Change board orientation in the diagram',
        text: 'If a diagram has h8 square in the bottom-left corner, click on the "Flip orientation" button to change the orientation of the diagram.',
      },
      {
        title: 'Edit diagram',
        text: <>Occasionally, e.g. with low-quality PDFs, it might happen that some pieces are not recognized correctly by the app. In this case, you can edit them manually and save so that since then they'll be always correct! In order to do that, click the edit icon <EditIcon color="primary" size="small" /> in the upper right corner.</>
      },
      {
        title: 'Export diagrams',
        text: <>Use <b>Study Creator</b> to export your diagrams, e.g. to Lichess Studies. First, enable the Study Creator by clicking the switch near it. Next, after you loaded a diagram to the board and set whose turn is it, click on the "Add" button. When you added all diagrams you want, click on "Export".</>
      },
      {
        title: "View position from Black's perspective",
        text: <>Click on the menu button{lichessMenuImg} and then on "Flip board".</>
      },
      {
        title: 'Show more engine suggestions',
        text: <>To see more engine suggestions, click on the menu button {lichessMenuImg} and then set "Multiple lines" option at the very bottom to 5 {lichessMultipleLinesImg}. If you don't see it, make sure to scroll to the very bottom of the options menu.</>,
      },

    ];

    return (
      <div className={classes.container}>
        <iframe src={`${settings.rootApiUrl}/third-party-cookies-check/start.html`} title="3rd-party-cookies-checker" style={{ display: 'none' }} />
        { fen !== startingFen && thirdPartyCookiesEnabled === false && doNotShowCookiesMessage === false && (
          <ThirdPartyCookiesDialog
            open={thirdPartyCookiesDialogOpen}
            onClose={() => this.closeCookiesDialog(false)}
            onCloseAndDoNotShow={() => this.closeCookiesDialog(true)}
          />
        )}
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'column'}}>
          { advancedOpen && (
          <div className={classes.studyContainer}>
            <div>
              <FormControlLabel
                 control={
                  <Switch
                    className={classes.studySwitch}
                    checked={studyMenuOpen}
                    size="medium"
                    onChange={this.studyMenuToggle}
                    value="Create study"
                  />
                 }
                 label="Study Creator"
                 labelPlacement="end"
                 className={classes.studySwitchForm}
              />
               <div className={classes.toolbarIcons}>
                 <Tooltip title="Copy FEN">
                   <IconButton
                     className={classes.fenCopyButton}
                     size="small"
                     aria-label="copy fen"
                     onClick={() => {
                       navigator.clipboard.writeText(fen.replaceAll('_', ' '));
                       this.setState({ fenCopiedOpen: true, shareFenMenu: null })}
                     }>
                     <CopyIcon
                       color="primary"
                       size="small"
                     />
                   </IconButton>
                 </Tooltip>
                 <Tooltip title="Share FEN">
                   <IconButton
                     className={classes.fenShareButton}
                     size="small"
                     onClick={(e) => { this.setState({ shareFenMenu: e.currentTarget }) }}
                     aria-label="share fen">
                     <ShareIcon
                       color="primary"
                       size="small"
                     />
                   </IconButton>
                 </Tooltip>
                 <Menu
                  anchorEl={shareFenMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={Boolean(shareFenMenu)}
                  onClose={() => { this.setState({ shareFenMenu: null })}}
                 >
                   { /*
                   <MenuItem
                     onClick={() => {
                       navigator.clipboard.writeText(fen.replaceAll('_', ' '));
                       this.setState({ fenCopiedOpen: true, shareFenMenu: null })}
                     }
                    >Copy FEN</MenuItem>
                    */ }
                    <MenuItem
                      component="a"
                      href={getChesscomAnalysisUrl(fen, pov)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >Chess.com</MenuItem>
                    <MenuItem
                      component="a"
                      href={getLichessAnalysisUrl(fen, pov)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >Lichess.org</MenuItem>
                 </Menu>
                 <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={fenCopiedOpen}
                    autoHideDuration={2000}
                    onClose={() => { this.setState({ fenCopiedOpen: false })}}
                    message="FEN copied!"
                    severity="success"
                  />
                 { onOpenEdit !== null && (
                   <Tooltip title="Edit diagram">
                     <IconButton
                       className={classes.editButton}
                       size="small"
                       onClick={() => onOpenEdit(fen)}
                       aria-label="edit position">
                       <EditIcon
                         color="primary"
                         size="small"
                       />
                     </IconButton>
                   </Tooltip>
                 )}
                 { onCommentChange && (
                   <Tooltip title="Comment">
                     <IconButton
                       className={classes.commentButton}
                       size="small"
                       onClick={() => this.handleToggleComment()}
                       aria-label="open comment">
                       <Badge color="secondary" variant="dot" invisible={comment === ""}>
                       { commentOpen
                       ? (
                       <CommentIcon
                         color="primary"
                         size="small"
                       />
                       )
                       : (
                       <CommentOutlinedIcon
                         color="primary"
                         size="small"
                       />
                       )
                       }
                       </Badge>
                     </IconButton>
                   </Tooltip>
                 )}
                 <Tooltip title="Show Help">
                   <IconButton
                     className={classes.setupButton}
                     size="small"
                     onClick={this.openSetupDialog}
                     aria-label="how to setup board">
                     <HelpIcon
                       color="primary"
                       size="small"
                     />
                   </IconButton>
                 </Tooltip>
               </div></div>
               { studyMenuOpen && (
                 <div className={classes.studyMenu}>
                   <Button
                     variant="outlined"
                     onClick={() => onStudyAdd()}
                     size="small"
                     className={classes.setupButton}
                     >add
                   </Button>
                  { studySize > 0 && (
                    <React.Fragment>
                      <Button size="small" variant="outlined" onClick={onStudyEdit}>Edit</Button>
                      <Button size="small" variant="outlined" onClick={onStudyExport}>Export ({studySize})</Button>
                      <Button variant="outlined" size="small" onClick={onStudyClear}>Clear</Button>
                    </React.Fragment>
                  )}
                  </div>
               )}
          </div>
          )}
          <div className={classes.topBar}>
            <div className={classes.topBarOptionsContainer}>
              <FormControlLabel
                 className={classes.playerSwitch}
                 control={
                  <PlayerSwitch
                    checked={currentPlayer === 'white'}
                    size="medium"
                    onChange={this.handlePlayerChange}
                    value={currentPlayer}
                  />
                 }
                 label={currentPlayer === 'white' ? "White's turn" : "Black's turn"}
                 labelPlacement="end"
               />
             <Tooltip title="Flip board">
               <IconButton
                 className={classes.flipButton}
                 size="small"
                 onClick={this.handlePovChange}
                 aria-label="flip board">
                 <RotateIcon
                   color="primary"
                   size="small"
                 />
               </IconButton>
             </Tooltip>
             { fen !== startingFen && (
               <Tooltip title="Starting position">
                 <IconButton
                   className={classes.restoreButton}
                   size="small"
                   onClick={onRestorePosition}
                   aria-label="starting position">
                   <RestoreIcon
                     color="primary"
                     size="small"
                   />
                 </IconButton>
               </Tooltip>
             )}
              <div className={classes.relatedContainer}>
               { fen !== startingFen && (
                <div className={classes.relatedWrapper}>
                  <RelatedChessableCourses user={user} fen={fen} size="small" />
                  <RelatedGames user={user} fen={fen} />
                  <RelatedVideos user={user} fen={fen} onClick={(data) => onOpenVideos(data)} />
                </div>
                )}
              </div>
              { isMobile && (
               <div className={classes.toolbarIcons}>
                 <IconButton
                   className={classes.advancedButton}
                   size="small"
                   onClick={() => this.handleToggleAdvanced()}
                   aria-label="open comment">
                   { advancedOpen
                     ? <ExpandLessIcon color="primary" size="small" />
                     : <ExpandMoreIcon color="primary" size="small" />
                   }
                 </IconButton>
               </div>
               )}
            </div>
          </div>
        </div>
        { commentOpen && (
          <div className={classes.commentContainer}>
            <TextField
              multiline
              rows={5}
              value={comment}
              fullWidth
              placeholder="Write your comments here"
              onChange={(e) => onCommentChange(e.target.value)}
              variant="outlined"
              margin="dense"
            />
          </div>
        )}
        <iframe
          src={`${getAnalysisBoardUrl(fen, pov)}`}
          allowFullScreen
          className={classes.lichessIframe}
          frameBorder={0}
          width="100%"
          height="100%"
          title="board analysis"
        ></iframe>
        <ConfirmationDialog
          open={setupDialog.open}
          title={settings.messages.setupDialog.title}
          content={
            <>
              <ul>
                <li style={{ listStyle: 'disc', marginLeft: '1rem', marginBottom: '0.5rem' }}>Click on the purple <b>Video Icon</b> in the bottom right corner to see the Video Tutorials.</li>
                <li style={{ listStyle: 'disc', marginLeft: '1rem' }}>Click on the <b>Chat Icon</b> in the bottom right corner to Chat with Us.</li>
              </ul>
              <p>
                The reader is using <a href="http://lichess.org/analysis" target="_blank" rel="noopener noreferrer">Lichess analysis window</a>.<br/>
                Below is the list of extra things you can do with the Analysis Board.
              </p>
              <h3>{settings.messages.setupDialog.analysisBoardTitle}</h3>
              { setupOptions.map((e, idx) => (
                <React.Fragment key={e.title}>
                  <h4>{idx+1}. {e.title}</h4>
                  <p>{e.text}</p>
                </React.Fragment>
              ))}
            </>
          }
          highlightOkButton
          onClose={this.closeSetupDialog}
          onOkClick={this.closeSetupDialog}
        />
      </div>
    )
  }
}

export default withStyles(styles)(BoardAnalysis);
