import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Search from './Search';
import Footer from '../../Site/Footer';

const styles = (theme) => ({
  root: {
    background: 'white',
    margin: '0 auto',
    marginTop: theme.spacing(10),
    textAlign: 'center',
    paddingBottom: theme.spacing(8),
    maxWidth: 1024,
    minHeight: 400,
  },
});

class SearchContent extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const { user, onUpgradeClick } = this.props;

    return (
      <div className={classes.root}>
        <Search user={user} onUpgradeClick={onUpgradeClick} />
        <Footer />
      </div>
    );
  }
}

SearchContent.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchContent);
