import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import Footer from './../../Site/Footer';

const styles = (theme) => ({
  root: {
    paddingTop: '8rem',
    '& p': {
      color: grey[800],
      lineHeight: '1.8rem',
    },
    '& h3': {
      marginTop: '4rem',
    },
    '& li': {
      padding: '0.5rem 0',
    },
  },
  contentWrapper: {
    marginTop: '2rem',
  },
});


class Resources extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const resources = [
      {
        title: 'Scan chess diagrams in your browser',
        links: [
          {title: 'Chrome Extension', url: 'https://chrome.google.com/webstore/detail/chessvisionai-chess-posit/johejpedmdkeiffkdaodgoipdjodhlld'},
          {title: 'Firefox Add-On', url: 'https://addons.mozilla.org/addon/chessvision-ai-for-firefox/'},
        ],
        text: <>The most popular chess browser extension</>,
      },
      {
        title: 'Scan chess diagrams with your phone',
        links: [
          {title: 'Android', url: 'https://play.google.com/store/apps/details?id=ai.chessvision.scanner'},
          {title: 'iOS', url: 'https://apps.apple.com/us/app/id1574933453'},
        ],
        text: <>Scan diagrams from prints and digital sources with your mobile device.</>,

      },
      {
        title: 'Search YouTube chess videos by content',
        links: [
          {title: 'my.chessvision.ai', url: 'https://my.chessvision.ai'},
        ],
        text: <>Define one or more filters, or use the predefined searches. The results point to the exact timestamps in the videos.</>,
      },
      {
        title: 'Scan chess diagrams on Twitter',
        links: [
          {title: '@ChessvisionAi', url: 'https://twitter.com/ChessvisionAI'},
        ],
        text: <>Reply to any tweet with a chess diagram and mention it with the "scan" keyword and it'll reply back with the analysis.</>,
      },
      {
        title: 'Scan chess diagrams on Discord',
        links: [
          {title: 'Add it to your server', url: 'https://discord.chessvision.ai/'},
        ],
        text: <>Recognizes chess positions from images posted on Discord.</>,
      },
      {
        title: 'Convert chess PGN to a beautiful PDF',
        links: [
          {title: 'pgn2pdf.com', url: 'https://pgn2pdf.com'},
        ],
        text: <>Paste your PGN, select options you want and generate a beautiful chess PDF.</>,
      }
    ];

    return (
      <div className={classes.root}>
        <div className={classes.faq}>
          <Container maxWidth="md">
            <Typography variant="h2" align="center">Resources</Typography>
            <div className={classes.contentWrapper}>
              { resources
                .map((e, idx) => (
                  <React.Fragment key={e.title}>
                    <h3>{e.title}</h3>
                    <div>
                    <ul>{e.links.map(({ title, url }) => <li><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></li>)}</ul>
                    </div>
                    <p>{e.text}</p>
                  </React.Fragment>
                ))
              }
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

Resources.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Resources);
