import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

class Stats extends Component {
  state = {
    numUsers: undefined,
    numBooks: undefined,
  };

  componentDidMount() {
    this.fetchStats();
  }

  fetchStats = () => {
    const url = `${process.env.REACT_APP_API_URL}/stats/`;
    return fetch(url)
    .then(response => response.json())
    .then(stats => {
      this.setState({
        numUsers: stats.num_users,
        numBooks: stats.num_books,
      });
    });
  };

  render() {
    const { numUsers, numBooks } = this.state;
    if (numUsers === undefined || numBooks === undefined) {
      return '';
    }
    const formatter = Intl.NumberFormat('en');
    return (
      <Typography color="secondary" variant="body2">
        as for now, <b>{formatter.format(numUsers)}</b> users <br/> unlocked full power of <b>{formatter.format(numBooks)}</b> chess eBooks.
      </Typography>
    );
  }

}

export default Stats;
