import React, { Component } from 'react';

import PropTypes from 'prop-types';

import firebase from 'firebase/app';

import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import GoogleIcon from 'mdi-material-ui/Google';
import appleLogo from '../../assets/apple-logo.png'

const styles = (theme) => ({
  dialogActions: {
    justifyContent: 'center',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1)
  },
  dialogActionsMobile: {
    display: 'grid',
    justifyContent: 'stretch',
    marginTop: theme.spacing(2.5),
    marginBottom: -theme.spacing(0.5)
  },
  button: {
    marginTop: theme.spacing(1.5),
    textTransform: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
  disabledButton: {
    color: '#fff !important',
    backgroundColor: `${theme.palette.gray} !important`,
  },
  buttonMobile: {
    marginBottom: theme.spacing(1.5)
  },
  google: {
    backgroundColor: '#4285f4',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4285f4',
    },
  },
  apple: {
    backgroundColor: '#000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
});

class AuthProviderList extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { isPerformingAuthAction } = this.props;

    // Events
    const { onAuthProviderClick } = this.props;

    return (
      <React.Fragment>
        <Hidden only="xs">
          <DialogActions className={classes.dialogActions}>
            <Button 
              className={`${classes.google} ${classes.button}`} 
              disabled={isPerformingAuthAction} 
              variant="contained" 
              onClick={() => onAuthProviderClick(new firebase.auth.GoogleAuthProvider())}
              startIcon={<GoogleIcon fontSize="small" />}
            >
              Sign in with Google
            </Button>
            <Button 
              className={`${classes.apple} ${classes.button}`} 
              disabled={isPerformingAuthAction} 
              variant="contained" 
              onClick={() => onAuthProviderClick(new firebase.auth.OAuthProvider('apple.com'))}
              startIcon={<img src={appleLogo} height={18} alt="Apple logo" style={{ filter: 'invert(1)'}} />}
            >
              Sign in with Apple
            </Button>
          </DialogActions>
        </Hidden>

        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <DialogActions className={classes.dialogActionsMobile} disableSpacing>
            <Button 
              className={`${classes.google} ${classes.buttonMobile}`} 
              disabled={isPerformingAuthAction} 
              variant="contained" 
              onClick={() => onAuthProviderClick(new firebase.auth.GoogleAuthProvider())}
              startIcon={<GoogleIcon fontSize="small" />}
            >
              Sign in with Google
            </Button>
            <Button 
              className={`${classes.apple} ${classes.buttonMobile}`} 
              disabled={isPerformingAuthAction} 
              variant="contained" 
              onClick={() => onAuthProviderClick(new firebase.auth.OAuthProvider('apple.com'))}
              startIcon={<img src={appleLogo} height={18} alt="Apple logo" style={{ filter: 'invert(1)'}} />}
            >
              Sign in with Apple
            </Button>
          </DialogActions>
        </Hidden>
      </React.Fragment>
    );
  }
}

AuthProviderList.propTypes = {
  classes: PropTypes.object.isRequired,

  isPerformingAuthAction: PropTypes.bool,

  onAuthProviderClick: PropTypes.func.isRequired
};

export default withStyles(styles)(AuthProviderList);
