import React from 'react';

import settings from '../../settings';

// free books helpers

const getHiddenFreeBooks = () => JSON.parse(window.localStorage.getItem(settings.values.freeBooksHiddenKey) || '{}');

const isFreeBookHidden = (checksum) => {
  const hiddenChecksums = getHiddenFreeBooks();
  return hiddenChecksums[checksum] === true;
}

const hideFreeBook = (checksum) => {
  const hiddenChecksums = getHiddenFreeBooks();
  hiddenChecksums[checksum] = true;
  window.localStorage.setItem(settings.values.freeBooksHiddenKey, JSON.stringify(hiddenChecksums));
}

// chess helpers

const flipPosition = (position) => {
  const rows = position.split('/');
  const flippedRows = rows.map(row => [...row].reverse().join('')).reverse();
  const flippedPosition = flippedRows.join('/');
  return flippedPosition;
};

const getPlayerFromFen = fen => fen === null ? 'white' : fen.split('_')[1] === 'w' ? 'white' : 'black';

const reverseFenOrientation = fen => {
  const [position, ...rest] = fen.split('_');
  return [flipPosition(position), ...rest].join('_');
}

const getFenWithPlayer = (fen, player) => {
  const parts = fen.split('_');
  const position = parts[0];
  const options = parts.slice(2);
  return [position, player[0], ...options].join('_');
};

const startingFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR_w_KQkq_-_0_1';

const getLichessAnalysisUrl = (fen, pov) => `https://lichess.org/analysis/${fen}?color=${pov}`;

const getChessvisionAnalysisUrl = (fen, pov) => `https://chessvision-embed-board.web.app/analysis/${fen}?color=${pov}`;

const getChesscomAnalysisUrl = (fen, pov) => {
  const flip = pov === 'black';
  // TODO: the flip query string parameter seems not to work correctly on chess.com
  // it seems to work when it's white to play in the FEN but not for black to play
  // i.e. it seems impossible to set the pov of the board when it's black to play
  return `https://www.chess.com/analysis?fen=${fen.replace(/_/g, '+')}&flip=${flip}&ref_id=${settings.values.CHESS_COM_REF_ID}`;
};

const getAnalysisBoardUrl = (fen, pov) => {
  // for Lichess analysis board
  return getLichessAnalysisUrl(fen === null ? startingFen : fen, pov);
  // for Chessvision analysis board
  return getChessvisionAnalysisUrl(fen === null ? startingFen : fen, pov);
}

const getFileType = file => {
  const { title, name, type } = file;
  /* example types:
  "image/png"
  "application/pdf"
  "video/mp4"
  "application/epub+zip"
  "image/webp"
  "text/html"
  "text/plain"
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": this was found for all docx documents
  "image/jpeg": found for both .jpeg and .jpg extensions
  "": i.e. empty string, this was observed for many djvu files we checked, and also for pgn files we checked
  "image/png"
  "image/gif" for gif image as expected
  ""
  */
  if (type) {
    if (type.startsWith('image/')) {
      // fix for some djvu misrecognized as image on Windows that was reported by a user
      const filename = title || name || "";
      const parts = filename.split('.');
      if (parts.length >= 2) {
        const ext = parts[parts.length-1].toLowerCase();
        if (ext === 'djvu') {
          return 'djvu';
        }
      }
      return 'image';
    }
    switch (type) {
      case 'application/pdf':
        return 'pdf';
      case 'application/epub+zip':
        return 'epub';
      case 'text/html':
        return 'html';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      default:
    }
  }

  const filename = title || name || "";
  const parts = filename.split('.');
  if (parts.length < 2) {
    return undefined;
  }
  const ext = parts[parts.length-1].toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
    return 'image';
  }
  return ext;
}

const getUnsupportedContent = fileType => {
  let unsupportedContent = null;
  switch (fileType) {
    case 'image':
      unsupportedContent = <>
        <p>
          It looks that you tried to open an image file which is not supported.
        </p>
        <p>
          Currently the Reader supports the following file formats: {settings.values.supportedFileFormats.join(', ')}
        </p>
        <p>
          However, our browser extensions and mobile apps can easily scan and analyze images files.
          You can download them from our homepage: <a href={settings.values.chessvisionHomepage} target="_blank" rel="noopener noreferrer">{settings.values.chessvisionHomepage}</a>
        </p>
      </>;
      break;
    /*
    case 'djvu':
      unsupportedContent = <>
        <p>
          It looks that you tried to open a DjVu file which is not yet supported. We are currently working on the DjVu support and it should be available later this year.
        </p>
        <p>
          Currently the Reader supports the following file formats: {settings.values.supportedFileFormats.join(', ')}
        </p>
        <p>
          For now, you can use the <a href="https://djvu2pdf.com/" target="_blank" rel="noopener noreferrer">djvu2pdf.com</a> online converter to convert
          your DjVu file to a PDF first, and then open the resulting PDF in our Reader.
        </p>
      </>;
      break;
    */
    /*
    case 'epub':
      unsupportedContent = <>
        <p>
          It looks that you tried to open an ePub file which is not yet supported. We may add a support for it in the future.
        </p>
        <p>
          Currently the Reader supports the following file formats: {settings.values.supportedFileFormats.join(', ')}
        </p>
        <p>
          For now, you can use the <a href="https://epubtopdf.com/" target="_blank" rel="noopener noreferrer">epubtopdf.com</a> online converter to convert
          your ePub file to a PDF first, and then open the resulting PDF in our Reader.
        </p>
      </>;
      break;
    */
    case 'pgn':
      unsupportedContent = <>
        <p>
          It looks that you tried to open a PGN file which is not supported by the Reader.
        </p>
        <p>
          Currently the Reader supports the following file formats: {settings.values.supportedFileFormats.join(', ')}
        </p>
        <p>
          However, if you want to create a PDF from your PGN you can use our <a href="https://pgn2pdf.com" target="_blank" rel="noopener noreferrer">pgn2pdf.com</a> tool
          to get a beautiful PDF from your PGN and then open the resulting PDF in our Reader.
        </p>
      </>;
      break;
    case 'html':
      unsupportedContent = <>
        <p>
          It looks that you tried to open a HTML file which is not supported by the Reader.
        </p>
        <p>
          Currently the Reader supports the following file formats: {settings.values.supportedFileFormats.join(', ')}
        </p>
        <p>
          However, you can use our browser extensions and mobile apps to easily scan and analyze chess diagrams from any website.
          You can download them from our homepage: <a href={settings.values.chessvisionHomepage} target="_blank" rel="noopener noreferrer">{settings.values.chessvisionHomepage}</a>
        </p>
      </>;
      break;
    default:
  }
  return unsupportedContent;
}

const getConversionContent = fileType => {
  const formattedFileType = {
    'epub': 'ePub',
    'djvu': 'DjVu',
  }
  if (formattedFileType.hasOwnProperty(fileType)) {
    return (
      <>
        <p>
          This looks like a {formattedFileType[fileType]} file and it has not yet been analyzed.
          The Reader can automatically convert it to PDF and then analyze it
          so that you can use it.
          Each file needs to be analyzed only once.
          Do you want the conversion and analysis to being now?
        </p>
      </>
    );
  }
  return null;
}

export {
  isFreeBookHidden,
  hideFreeBook,
  reverseFenOrientation,
  getPlayerFromFen,
  startingFen,
  flipPosition,
  getFenWithPlayer,
  getLichessAnalysisUrl,
  getChesscomAnalysisUrl,
  getAnalysisBoardUrl,
  getFileType,
  getUnsupportedContent,
  getConversionContent,
};
