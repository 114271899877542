import React from 'react';

import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, useTheme } from '@material-ui/core/styles';

import appstore from '../assets/appstore.png';
import playstore from '../assets/playstore.png';
import settings from '../settings';
import { emitEvent } from '../events';

const styles = (theme) => ({
  root: {
    fontSize: '1.124rem',
    backgroundSize: 32,
    marginLeft: 2,
    marginRight: 2,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    transitionDuration: '.15s',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '12px center',
    backgroundColor: '#000 !important',
    fontSize: 16,
    color: '#fff !important',
    height: 48,
    paddingLeft: 30,
    lineHeight: 48,
    borderRadius: 10,
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.90,
    }
  },
  mobileMedium: {
    width: 190,
    height: 63,
    backgroundSize: '95%',
    backgroundPosition: 'center',
  },
  mobileSmall: {
    width: 160,
    backgroundSize: '95%',
    backgroundPosition: 'center',
  },
  mobileTiny: {
    width: 150,
    backgroundSize: '90%',
    backgroundPosition: 'center',
  },
  ios: {
    backgroundImage: `url(${appstore})`,
  },
  android: {
    backgroundImage: `url(${playstore})`,
  },
});

const MobileButton = ({ classes, onClick, store, url, size = "medium", style = {} }) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('xs'));
  let sizeClass = classes.mobileMedium;
  if (size === "small") {
    sizeClass = classes.mobileSmall;
  } else if (size === "tiny") {
    sizeClass = classes.mobileTiny;
  }
  const storeClass = store === 'android' ? classes.android : classes.ios;
  return (
    <Button 
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
      href={url} 
      fullWidth={!desktop} 
      className={`${[classes.root, sizeClass, storeClass].join(' ')}`}
      style={style}
    />
  );
};

const MobileButtonWithStyles = withStyles(styles)(MobileButton);

export const AndroidButton = ({onClickEventType, size = "medium", style = {}}) => {
  const onClick = onClickEventType 
  ? () => {emitEvent(onClickEventType)}
  : () => {};
  return (
    <MobileButtonWithStyles 
      store="android" 
      onClick={onClick}
      url={settings.values.playStoreUrl}
      style={style}
      size={size}
    />
  );
};

export const AppleButton = ({onClickEventType, size = "medium", style = {}}) => {
  const onClick = onClickEventType 
  ? () => {emitEvent(onClickEventType)}
  : () => {};
  return (
    <MobileButtonWithStyles 
      store="apple" 
      onClick={onClick}
      url={settings.values.appStoreUrl}
      style={style}
      size={size}
    />
  );
};