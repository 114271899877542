import React, { Component} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import YouTubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 10,
  },
  colorPrimary: {
    color: '#fff',
    backgroundColor: '#000',
  },
  colorSecondary: {
    color: '#fff',
    backgroundColor: '#ccc',
  }
}))(Badge);


class RelatedVideos extends Component {

  state = {
    videos: undefined,
  };

  componentDidMount() {
    const { fen } = this.props;
    this.fetchRelatedVideos(fen);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fen !== this.props.fen) {
      this.fetchRelatedVideos(nextProps.fen);
    }
  }

  fetchRelatedVideos = (fen) => {
    this.setState({
      videos: undefined,
    });
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/search_videos?fen=${fen}`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
       })
      });
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.json())
    })
    .then(data => {
      this.setState({
        videos: data,
      });
    })
    .catch(e => {
      this.setState({
        videos: [],
      });
    })
  }

  renderButton() {
    const { onClick } = this.props;
    const { videos } = this.state;
    if (videos === undefined) {
      return (
        <CircularProgress size={16} color="primary" />
      )
    }
    const numVideos = videos.length;
    const disabled = numVideos === 0;

    return (
      <Tooltip title="Videos with this position">
        <IconButton
          onClick={() => onClick(videos)}
          disableRipple
          disabled={disabled}
          style={{ backgroundColor: 'transparent', padding: 0 }}
        >
          <StyledBadge badgeContent={numVideos} max={10} showZero color={disabled ? 'secondary' : 'primary'}>
            <YouTubeIcon style={{ color: disabled ? 'ccc' : '#ff0000', fontSize: 32 }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  }

  render() {
    return (
      <div style={{ minWidth: 50, textAlign: 'center', marginRight: '0.35rem' }}>
        {this.renderButton()}
      </div>
    );
  }
}

export default RelatedVideos;
