import React from 'react';

import ReactPlayer from 'react-player/youtube';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

import { makeStyles } from '@material-ui/core/styles';

import TimeAgo from 'react-timeago'

import { dateFromInt, nFormatter, truncate } from './utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginBottom: '1.5rem',
    borderBottom: '1px solid #ccc',
  },
  textContainer: {
    flex: 1,
    fontSize: '0.8rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  titleWrapper: {
    fontSize: '1rem',
  },
  title: {
    textDecoration: 'none',
    color: '#030303',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  channelWrapper: {
    marginTop: '0.75rem',
  },
  channel: {
    textDecoration: 'none',
    color: '#030303',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  playerContainer: {
    position: 'relative',
    paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
    maxWidth: 'none',
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  playIcon: {
    fontSize: '64px',
  },
}));

const Video = ({ video, channel, timestamp }) => {
  const classes = useStyles();

  // we link to 1s before the exact timestamp so that it improves
  // user's experience while starting watching the video
  timestamp = timestamp - 1;

  // we max with 1 because apparently YouTube doesn't understand timestamp 0
  // in the video urls, i.e. when there is a t=0 in the URL, YouTube seems
  // to ignore it and opens the video at the last remembered timestamp, i.e.
  // the timestamp you finished watching the video last time
  timestamp = Math.max(1, timestamp);
  const url = `https://www.youtube.com/watch?v=${video.id}&t=${timestamp}`;
  const timestampStr = `${String(Math.floor(timestamp / 60)).padStart(2, '0')}:${String(timestamp % 60).padStart(2, '0')}`;
  return (
    <div className={classes.container}>
      <div className={classes.playerContainer}>
        <ReactPlayer
          url={url}
          controls={true}
          light={true}
          width="100%"
          height="100%"
          playing={true}
          playIcon={<PlayCircleFilledWhiteIcon style={{ fontSize: '64px', fill: 'red', backgroundColor: '#fff', borderRadius: '50%' }} />}
          className={classes.player}
        />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.titleWrapper}>
          <a href={url} target="_blank" rel="noopener noreferrer" className={classes.title}>{truncate(video.title, 70)}</a>
        </div>
        <div className={classes.infoContainer}>
          <span className={classes.timestamp}>at {timestampStr}</span> •&nbsp;
          <span className={classes.viewCount}>
            {nFormatter(video.views)} views
          </span> •&nbsp;
          <span className={classes.date}>
            <TimeAgo date={dateFromInt(video.upload_date)} />
          </span>
          <span className={classes.channelWrapper}>
            <a href={`https://youtube.com/channel/${channel.id}`} target="_blank" rel="noopener noreferrer" className={classes.channel}>{truncate(channel.name, 25)}</a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Video;
