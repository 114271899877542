// React
import React, { Component } from 'react';

import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";

import PropTypes from 'prop-types';

// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/performance';
import 'firebase/storage';

import readingTime from 'reading-time';

// Material-UI
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';

// Custom
import settings from '../settings';

import Bar from './Bar';

import Reader from '../content/ReaderContent/Reader';
import Settings from '../content/SettingsContent';
import MyDocumentsContent from '../content/MyDocumentsContent/MyDocumentsContent';
import SearchContent from '../content/SearchContent/SearchContent';
import FAQ from '../content/FAQContent/FAQ';
import Resources from '../content/ResourcesContent/Resources';
import TermsContent from '../content/LandingContent/TermsContent';
import CheckoutSuccessfulContent from '../content/CheckoutRedirectContent/CheckoutSuccessfulContent';
import CheckoutCanceledContent from '../content/CheckoutRedirectContent/CheckoutCanceledContent';
import NotFoundContent from '../content/NotFoundContent/NotFoundContent';

import SettingsDialog from '../dialogs/SettingsDialog/SettingsDialog';
import SubscriptionPlansDialog from '../dialogs/SubscriptionPlansDialog/SubscriptionPlansDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog/ConfirmationDialog';

import MediaQuery from 'react-responsive';

import VideoTutorialsDialog from '../content/VideoTutorials';

import { eventTypes, emitEvent } from '../events';

if (firebase.apps.length === 0) {
  firebase.initializeApp(settings.credentials.firebase);
}

// eslint-disable-next-line no-unused-vars
const performance = firebase.performance();
const storage = firebase.storage();

const initialPalette = {
  primary: settings.theme.primaryColor,
  secondary: settings.theme.secondaryColor,
  background: settings.theme.backgroundColor,
  documentWidth: settings.theme.documentWidth,
};

const baseTheme = {
  typography: {
    h2: {
      [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
        fontSize: '2.75rem',
      },
    },
    body1: {
      [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
        fontSize: '0.9rem',
      },
    },
  }
};

const getTheme = ({ name, layoutDirection }) => createMuiTheme({
  name,
  layoutDirection,
  palette: initialPalette,
  ...baseTheme,
})

// theme = responsiveFontSizes(theme);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPerformingAuthAction: false,

      settingsDialog: {
        open: false
      },

      signOutDialog: {
        open: false
      },

      subscriptionPlansDialog: {
        open: false
      },

      emailSupportDialog: {
        open: false
      },

      chatDisabledDialog: {
        open: false
      },

      snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false
      },

      videoTutorialsDialog: {
        open: false,
      },

      theme: localStorage.getItem('chessvision/theme-mode') || 'light',
      layoutDirection: localStorage.getItem('chessvision/layout-direction') || 'ltr',
    };
  }

  getFileUrl = (checksum) => {
    const { user } = this.props;

    const ref = storage.ref(`uploads/${user.uid}/${checksum}`);
    return ref.getDownloadURL();
  }

  uploadFile = (folder, file, checksum, onStateChanged, onUploadSuccess, onError) => {
    const { user } = this.props;

    const ref = storage.ref(`${folder}/${user.uid}/${checksum}`);
    const uploadTask = ref.put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        if (onStateChanged) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onStateChanged(snapshot.state, progress);
        }
      }, function(error) {
        if (onError) {
          onError(error);
        }
      }, function() {
        // Upload completed successfully
        if (onUploadSuccess) {
          onUploadSuccess(uploadTask.snapshot);
        }
    });
  }

  getSubscription = (onExists, onDoesNotExist) => {
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/subscription/`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
        }),
      })
      .then(response => {
        if (response.ok) {
          return response.json()
          .then(data => {
            return onExists(data);
          })
        } else if (response.status === 404) {
          onDoesNotExist();
        } else {
          this.setState({
            status: {
              running: false,
              progress: null,
              text: 'Unknown error occurred while checking subscription status',
            },
          });
        }
      });
    });
  }

  getSubscriptionCurrentPeriodEnd = (onExists, onDoesNotExist) => {
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/subscription-current-period-end/`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
        }),
      })
      .then(response => {
        if (response.ok) {
          return response.json()
          .then(({current_period_end}) => {
            return onExists(current_period_end);
          })
        } else if (response.status === 404) {
          onDoesNotExist();
        } else {
          this.setState({
            status: {
              running: false,
              progress: null,
              text: 'Unknown error occurred while checking subscription status',
            },
          });
        }
      });
    });
  }

  cancelSubscription = () => {
    const { user } = this.props;

    return user.getIdToken().then(authToken => {
      const url = `${process.env.REACT_APP_API_URL}/subscription/`;
      return fetch(url, {
        method: 'PUT',
        body: JSON.stringify({
          cancel: true,
        }),
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
        }),
      })
      .then(response => response.json());
    });
  }

  signOut = () => {
    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      auth.signOut().then(() => {
        emitEvent(eventTypes.signOut);
        this.closeSignOutDialog(() => {
          this.props.history.push('/')
          this.openSnackbar('Signed out');
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  openSettingsDialog = () => {
    this.setState({
      settingsDialog: {
        open: true
      }
    });
  };

  closeSettingsDialog = (callback) => {
    this.setState({
      settingsDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openVideoTutorialsDialog = (eventType) => {
    emitEvent(eventType || eventTypes.videoTutorialsDialogOpenMenu);
    this.setState({
      videoTutorialsDialog: {
        open: true,
      }
    });
  };

  closeVideoTutorialsDialog = () => {
    this.setState({
      videoTutorialsDialog: {
        open: false,
      }
    });
  };

  openSubscriptionPlansDialog = () => {
    this.setState({
      subscriptionPlansDialog: {
        open: true
      }
    });
  };

  closeSubscriptionPlansDialog = (callback) => {
    emitEvent(eventTypes.subscriptionPlansDialogCancel);

    this.setState({
      subscriptionPlansDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openSignOutDialog = () => {
    this.setState({
      signOutDialog: {
        open: true
      }
    });
  };

  closeSignOutDialog = (callback) => {
    this.setState({
      signOutDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  toogleTheme = () => {
    const { theme } = this.state;
    const newTheme = theme === 'light' ? 'dark' : 'light';
    this.setState({
      theme: newTheme,
    }, () => {
      localStorage.setItem('chessvision/theme-mode', newTheme);
    });
  }
  changeLayoutDirection = (newLayoutDirection) => {
    this.setState({
      layoutDirection: newLayoutDirection,
    }, () => {
      localStorage.setItem('chessvision/layout-direction', newLayoutDirection);
    })
  }

  openChat = () => {
    window.$crisp.push(['do', 'chat:open']);
  }

  openEmailSupportDialog = () => {
    this.setState({
      emailSupportDialog: {
        open: true,
      }
    });
  }

  closeEmailSupportDialog = (callback) => {
    this.setState({
      emailSupportDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  openChatDisabledDialog = () => {
    this.setState({
      chatDisabledDialog: {
        open: true,
      }
    });
  }

  closeChatDisabledDialog = (callback) => {
    this.setState({
      chatDisabledDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  render() {
    const { user } = this.props;

    const {
      isPerformingAuthAction,
      settingsDialog,
      subscriptionPlansDialog,
      signOutDialog,
      snackbar,
      theme,
      layoutDirection,
      videoTutorialsDialog,
      emailSupportDialog,
      chatDisabledDialog,
    } = this.state;

    return (
      <MuiThemeProvider theme={getTheme({ name: theme, layoutDirection })}>
        <div>
          <React.Fragment>
            <Bar
              isPerformingAuthAction={isPerformingAuthAction}
              getSubscription={this.getSubscription}
              getSubscriptionCurrentPeriodEnd={this.getSubscriptionCurrentPeriodEnd}
              onSettingsClick={this.openSettingsDialog}
              onTutorialsClick={this.openVideoTutorialsDialog}
              onSignOutClick={this.openSignOutDialog}
              theme={theme}
              onThemeToggle={this.toogleTheme}
              layoutDirection={layoutDirection}
              onLayoutDirectionToggle={this.toggleLayoutDirection}
              onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
              onEmailSupportClick={this.openEmailSupportDialog}
              onChatClick={this.openChatDisabledDialog}
            />

            <Switch>
              <Route
                path={[settings.routes.HOME, `${settings.routes.DOCUMENTS}/:checksum`]}
                exact
                render={props => {
                  const { location, match, ...rest } = props;
                  if (!location.state?.remoteFile && match.params?.checksum) {
                    if (!location.state) {
                      location.state = {}
                    }
                    location.state.remoteFile = {
                      id: 999999,
                      checksum: match.params.checksum,
                      title: 'Cloud File',
                    }
                  }
                  return (
                    <MediaQuery maxWidth={settings.values.maxMobileWidth}>
                    {(isMobile) => (
                    <Reader
                      isMobile={isMobile}
                      user={user}
                      getFileUrl={this.getFileUrl}
                      uploadFile={this.uploadFile}
                      onDocumentWidthChange={this.changeDocumentWidth}
                      isPerformingAuthAction={isPerformingAuthAction}
                      onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
                      onTutorialsClick={this.openVideoTutorialsDialog}
                      location={location}
                      {...rest}
                    />
                    )}
                    </MediaQuery>
                  );
                }}
              />
              <Route path={settings.routes.DOCUMENTS} exact>
                <MyDocumentsContent user={user} />
              </Route>
              <Route path={settings.routes.SETTINGS} exact>
                <Settings 
                  user={user}
                  isPerformingAuthAction={isPerformingAuthAction}
                  onCancelSubscriptionClick={this.cancelSubscription}
                  onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
                  getSubscription={this.getSubscription}
                  getSubscriptionCurrentPeriodEnd={this.getSubscriptionCurrentPeriodEnd}
                  onExtendClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
                  onVerifyEmailAddressClick={this.verifyEmailAddress}
                  onDocumentWidthChange={this.changeDocumentWidth}
                  layoutDirection={layoutDirection}
                  onLayoutDirectionChange={this.changeLayoutDirection}
                />
              </Route>
              <Route path={settings.routes.SEARCH} exact>
                <SearchContent
                  user={user}
                  onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
                />
              </Route>
              <Route path={settings.routes.FAQ} exact>
                <FAQ />
              </Route>
              <Route path={settings.routes.RESOURCES} exact>
                <Resources />
              </Route>
              <Route path={settings.routes.TERMS} exact>
                <TermsContent />
              </Route>
              <Route path={settings.routes.CHECKOUT_SUBSCRIPTION_SUCCESSFUL} exact render={() => {
                const { title, text } = settings.messages.checkout.subscription.successful;
                return <CheckoutSuccessfulContent title={title} text={text} />;
                }}
              />
              <Route path={settings.routes.CHECKOUT_SUBSCRIPTION_CANCELED} exact render={() => {
                const { title, text } = settings.messages.checkout.subscription.canceled;
                return <CheckoutCanceledContent title={title} text={text} />;
                }}
              />
              <Route path={settings.routes.CHECKOUT_PURCHASE_SUCCESSFUL} exact render={() => {
                const { title, text } = settings.messages.checkout.purchase.successful;
                return <CheckoutSuccessfulContent title={title} text={text} />;
                }}
              />
              <Route path={settings.routes.CHECKOUT_PURCHASE_CANCELED} exact render={() => {
                const { title, text } = settings.messages.checkout.purchase.canceled;
                return <CheckoutCanceledContent title={title} text={text} />;
                }}
              />
              <Route component={NotFoundContent} />
            </Switch>

            <VideoTutorialsDialog
              open={videoTutorialsDialog.open}
              onClose={this.closeVideoTutorialsDialog}
            />
            <React.Fragment>
              <SettingsDialog
                open={settingsDialog.open}

                user={user}
                isPerformingAuthAction={isPerformingAuthAction}

                onClose={this.closeSettingsDialog}
                onVerifyEmailAddressClick={this.verifyEmailAddress}
                onDocumentWidthChange={this.changeDocumentWidth}
                onCancelSubscriptionClick={this.cancelSubscription}
                getSubscription={this.getSubscription}
                onUpgradeClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}
                onExtendClick={() => this.closeSettingsDialog(this.openSubscriptionPlansDialog)}

                layoutDirection={layoutDirection}
                onLayoutDirectionChange={this.changeLayoutDirection}
              />

              <ConfirmationDialog
                open={emailSupportDialog.open}
                title="Email Us — We’re Here to Help!"
                content={
                  <p>
                    Email us at <a 
                    href={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader general support request`}
                    rel="noopener noreferrer"
                    target="_blank"
                    >{settings.values.supportEmail}</a> for any support you need or simply click the button below - expect quick and detailed support every time!
                  </p>
                }
                okText="Email Us"
                highlightOkButton
                cancelText="Cancel"
                onClose={this.closeEmailSupportDialog}
                onCancelClick={this.closeEmailSupportDialog}
                onOkClick={() => { 
                  this.closeEmailSupportDialog();
                  window.open(`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader general support request`); 
                }}
              />

              <ConfirmationDialog
                open={chatDisabledDialog.open}
                title="We've Shifted to Email!"
                content={
                  <>
                    <p>📩 Goodbye Chat, Hello Email Support!</p>

                    <p>
                      To enhance our support services, we've moved from chat to email. This change ensures you receive:
                    </p>
                
                    <ul>
                      <li>- Faster Replies: Quicker responses to your inquiries.</li>
                      <li>- Detailed Support: More comprehensive and tailored assistance.</li>
                      <li>- Continuous Conversations: Previous chat queries? Just email us to pick up where you left off.</li>
                    </ul>
                
                    <p>
                      Email us at <a 
                      href={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader general support request`}
                      rel="noopener noreferrer"
                      target="_blank"
                      >{settings.values.supportEmail}</a> for any support you need or simply click the button below - expect quick and detailed support every time!
                    </p>
                  </>
                }
                okText="Email Us"
                highlightOkButton
                cancelText="Cancel"
                onClose={this.closeChatDisabledDialog}
                onCancelClick={this.closeChatDisabledDialog}
                onOkClick={() => { 
                  this.closeChatDisabledDialog();
                  window.open(`mailto:${settings.values.supportEmail}?subject=Chessvision.ai eBook Reader general support request`); 
                }}
              />

              <SubscriptionPlansDialog
                open={subscriptionPlansDialog.open}
                title="Select Your Plan"
                cancelText="Cancel"
                user={user}
                onClose={this.closeSubscriptionPlansDialog}
                onCancelClick={this.closeSubscriptionPlansDialog}
              />

              <ConfirmationDialog
                open={signOutDialog.open}

                title="Do you want to logout?"
                content=""
                okText="Logout"
                disableOkButton={isPerformingAuthAction}
                highlightOkButton

                onClose={this.closeSignOutDialog}
                onCancelClick={this.closeSignOutDialog}
                onOkClick={this.signOut}
              />
            </React.Fragment>
            <Snackbar
              autoHideDuration={snackbar.autoHideDuration}
              message={snackbar.message}
              open={snackbar.open}
              onClose={this.closeSnackbar}
            />
          </React.Fragment>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withRouter(App);
