import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';

import CheckIcon from '@material-ui/icons/CheckCircle';
import ReaderIcon from '@material-ui/icons/LibraryBooks';

import EmptyState from '../../layout/EmptyState/EmptyState';

import settings from '../../settings';

import { eventTypes, emitEvent } from '../../events';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    minHeight: '100vh',
  },
  emptyStateIcon: {
    fontSize: theme.spacing(12)
  },

  button: {
    marginTop: theme.spacing(2)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
});

class CheckoutSuccessfulContent extends Component {
  constructor(props) {
    super(props);
    emitEvent(eventTypes.checkoutSuccess);
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { title, text } = this.props;

    return (
      <div className={classes.root}>
        <EmptyState
          icon={<CheckIcon className={classes.emptyStateIcon} color="inherit" />}
          title={title}
          description={text}
          button={
            <Fab className={classes.button} color="secondary" component={Link} to={settings.routes.HOME} variant="extended">
              <ReaderIcon className={classes.buttonIcon} /> Go to the Reader
            </Fab>
          }
        />
      </div>
    );
  }
}

CheckoutSuccessfulContent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(CheckoutSuccessfulContent);
